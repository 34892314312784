import { useSelector } from "react-redux";
import Map from "../common/Map/map";
import { useEffect, useState } from "react";
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from "../common/searchBar";
import { serviceListingsData } from "../../services/index.service";
import { HTTP_STATUS_CODES } from "../../constants/ApiUrl";
import Loader from "../common/Loader";
import AllServicesPagination from "../common/allServicesPagination";

const AllServices = () => {
    const navigate = useNavigate();
    const reduxData = useSelector((state) => state.allCategories.navbarData);

    const [searchParams] = useSearchParams();
    const urlId = searchParams.get('id');
    const urlLocation = searchParams.get('location');
    const urlSearchText = searchParams.get('search');

    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [isListingVisible, setIsListingVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [listingData, setLisitingData] = useState([]);

    const [userLocation, setUserLocation] = useState(null);
    const [activeButton, setActiveButton] = useState('all');
    const [isMapContentOpened, setIsMapContentOpened] = useState(false);
    const [filters, setFilters] = useState({
        searchText: urlSearchText ?? "",
        distance: "5",
        location: urlLocation ?? "",
        category: urlId ? [urlId] : [],
        sub_category: [],
        order: "Latest",
        page: 1,
        page_size: 10,
        active_filter: "all",
    })
    const [showGrid, setShowGrid] = useState({
        listView: true,
        gridView: false
    });

    useEffect(() => {
        getLocation();
        initializeTooltip();
    }, [])

    const initializeTooltip = () => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }

    useEffect(() => {
        getCategories(filters);
    }, [filters.searchText, filters.category, filters.distance, filters.location, filters.order, filters.sub_category, filters.page, filters.active_filter])

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        setFilters({ ...filters, active_filter: buttonName })
        // Set the clicked button as active
    };

    const handleAllFiltersClick = () => {
        setIsSidebarActive(!isSidebarActive);
        setIsMapContentOpened(false);
    };

    const handleSidebarCollapseClick = () => {
        setIsSidebarActive(!isSidebarActive);
        // setIsMapContentOpened(!isMapContentOpened);
    };

    const handleMapCollapseClick = () => {
        setIsMapContentOpened(!isMapContentOpened);
    };

    const handleFilterCloseClick = () => {
        setIsSidebarActive(false);
    };

    const handleShowGridView = () => {
        setShowGrid({
            gridView: true,
            listView: false
        })
    }

    const handleShowListView = () => {
        setShowGrid({
            gridView: false,
            listView: true
        })
    }

    const handleChangeFilters = (e) => {
        const { value, name, checked } = e.target;
        if (name === "category") {
            // Update the category array
            setFilters({
                ...filters,
                category: checked
                    ? [...filters.category, value]
                    : filters?.category?.filter(category => category !== value),
                sub_category: []
            });

        }
        else if (name === "sub_category") {
            // Update the category array
            setFilters({
                ...filters,
                sub_category: checked
                    ? [...filters?.sub_category, value]
                    : filters?.sub_category.filter(category => category !== value)
            });
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    }

    const trimText = (text) => {
        if (text.length > 70) {
            return `${text.slice(0, 70)}...`
        } else {
            return text
        }
    }

    const getCategories = async (filters) => {
        try {
            const params = {
                search_text: filters.searchText || '',
                distance: filters.distance || '',
                city: filters.location || '',
                category_id: filters.category.join(","),
                order: filters.order || '',
                page: filters.page,
                page_size: filters.page_size,
                sub_category_id: filters.sub_category?.join(","),
                is_filter: ((filters.searchText || filters.distance || filters.location) ? true : false),
                active: filters.active_filter
            };

            setLoading(true)
            serviceListingsData(params)
                .then((response) => {
                    if (response.status === HTTP_STATUS_CODES.OK) {
                        setLoading(false);
                        setLisitingData(response.data);
                        // toast.success(response.data.message, { toastId: "lisitng_fetch" });
                    }
                })
                .catch((err) => {
                    // console.log(err, "error")
                    setLoading(false);
                })

        } catch (error) {
            console.log(error)
        }
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting location: ", error);
                }
            );
        } else {
            console.error("Geolocation not supported");
        }
    };

    const handleClear = () => {
        setFilters({
            ...filters,
            searchText: urlSearchText ?? "",
            distance: "",
            location: urlLocation ?? "",
            category: [],
            order: "Latest",
            page: 1,
            page_size: 10,
        });
        navigate("/all-category");
    }

    const handleClick = (el) => {
        const phoneNumber = el.listing_whatsapp.replace(/\s+/g, ""); // Remove spaces if any
        const whatsappUrl = `https://wa.me/${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappUrl, "_blank");
    };

    const markFav = () => {
        const isTokenAvailable = localStorage.getItem("token");
        if (!isTokenAvailable) {
            navigate('/login')
        } else {

        }
    }

    const handleListingVisibilityClick = () => {
        setIsListingVisible(!isListingVisible);
    };

    return (<>
        <Loader loading={loading} />

        {/* <!-- start header search bar  --> */}
        <SearchBar reduxData={reduxData}
            handleChangeFilters={handleChangeFilters}
            handleSidebarCollapseClick={handleSidebarCollapseClick}
            handleMapCollapseClick={handleMapCollapseClick}
            getCategories={getCategories}
            filters={filters}
        />
        {/* <!-- end /. header search bar  --> */}

        <div className="map-wrapper">
            {/* <!-- start sidebar filters --> */}
            <div className={`sidebar-filters js-sidebar-filters-mobile ${isSidebarActive ? 'active' : ''}`}>
                {/* <!-- filter header --> */}
                <div className="border-bottom d-flex justify-content-between align-items-center p-3 sidebar-filters-header d-xl-none">
                    <div className="align-items-center btn-icon d-flex filter-close justify-content-center rounded-circle" onClick={handleFilterCloseClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                    </div>
                    <span className="fs-3 fw-semibold">Filters</span>
                    <span className="text-primary fw-medium">Clear</span>
                </div>
                {/* <!-- end /. filter header --> */}
                <div className="sidebar-filters-body p-3 p-xl-4">

                    <div className="mb-4 border-bottom pb-4">
                        <div className="mb-3">
                            <h4 className="fs-5 fw-semibold mb-1">Advertise section</h4>
                            {/* <p className="mb-0 small">Select min and max price range</p> */}
                        </div>
                        {/* <!-- Start Range Slider --> */}
                        <div className="js-range-slider"></div>
                        {/* <!-- End Range Slider --> */}
                    </div>

                    <div className="mb-4 border-bottom pb-4">
                        <div className="mb-3">
                            <h4 className="fs-5 fw-semibold mb-2">Categories</h4>
                            <p className="mb-0 small">Filter by category</p>
                        </div>

                        <div style={{ height: "200px", overflow: "auto" }}>
                            {/* <!-- Start Form Check --> */}
                            {reduxData?.categories?.length > 0 && reduxData?.categories?.map((el, index) => {
                                return (
                                    <div className="form-check mb-2" key={index}>
                                        <input className="form-check-input" type="checkbox" checked={filters.category.includes(el.category_id.toString())} value={el.category_id} id={index} name="category" onChange={handleChangeFilters} />
                                        <label className="form-check-label" htmlFor={index}>{el.category_name}<span className="count fs-13 ms-1 text-muted">{el.listing_count}</span></label>
                                    </div>
                                )
                            })}
                            {/* <!-- End Form Check --> */}
                        </div>
                    </div>

                    {/* Sub category will be added here  */}
                    <div className="mb-4 border-bottom pb-4">
                        <div className="mb-3">
                            <h4 className="fs-5 fw-semibold mb-1">Sub Categories</h4>
                        </div>
                        <div style={{ height: listingData?.subcategories?.length > 0 ? "200px" : "auto", overflow: "auto" }}>
                            {/* <!-- Start Form Check --> */}
                            {listingData?.subcategories?.length > 0 ? listingData?.subcategories?.map((el, index) => {
                                return (
                                    <div className="form-check mb-2" key={index}>
                                        <input className="form-check-input" type="checkbox" checked={filters.sub_category.includes(el.id.toString())} value={el.id} id={`sub${index}`} name="sub_category" onChange={handleChangeFilters} />
                                        <label className="form-check-label" htmlFor={`sub${index}`}>{el.name}<span className="count fs-13 ms-1 text-muted">{el.sub_count ?? 0}</span></label>
                                    </div>
                                )
                            }) :
                                <p className="mb-0 small">To retrieve subcategories, please select a category from the list above.</p>
                            }
                            {/* <!-- End Form Check --> */}
                        </div>
                    </div>

                    <div className="mb-4 border-bottom pb-4">
                        <div className="mb-3">
                            <h4 className="fs-5 fw-semibold mb-1">Order by</h4>
                            <p className="mb-0 small">Filter by order</p>
                        </div>
                        {/* <!-- Start Select2 --> */}
                        <select className="form-select">
                            <option value="1">Latest</option>
                            <option value="2">Nearby</option>
                            <option value="3">Top rated</option>
                            <option value="4">Random</option>
                            <option value="5">A-Z</option>
                        </select>
                        {/* <!-- /.End Select2 --> */}
                    </div>

                    {/* <!-- start clear filters --> */}
                    <span className="align-items-center d-flex fw-medium gap-2 justify-content-center mt-2 small text-center" onClick={handleClear} style={{ cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                        Clear filters
                    </span>
                    {/* <!-- end /. clear filters --> */}
                </div>
            </div>
            {/* <!-- end /. sidebar filters --> */}
            {/* <!-- start items content --> */}
            <div className={`items-content bg-light d-flex flex-column py-3 p-xl-4 ${!isListingVisible ? 'translate-end' : ''}`}>
                <div className="d-flex flex-wrap align-items-center mb-3 gap-2 justify-content-around">

                    <div className="col fs-18 text-nowrap"> All<span className="fw-bold text-dark ms-1">{listingData?.total_count ? listingData?.total_count : 0}</span> listing found</div>

                    {/* Sort By Menu */}
                    <div className="border-0 card d-inline-flex flex-row flex-wrap gap-1 p-1 rounded-3 shadow-sm">
                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'all' ? 'activeToolTip' : ''}`} // Add 'active' class if selected
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="All"
                            onClick={() => handleButtonClick('all')}
                        >
                            <i className="fa-solid fa-globe"></i>
                        </button>

                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'popular' ? 'activeToolTip' : ''}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Popular"
                            onClick={() => handleButtonClick('popular')}
                        >
                            <i className="fa-solid fa-star"></i>
                        </button>

                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'open' ? 'activeToolTip' : ''}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Open"
                            onClick={() => handleButtonClick('open')}
                        >
                            <i className="fa-solid fa-door-open"></i>
                        </button>

                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'verified' ? 'activeToolTip' : ''}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Verified"
                            onClick={() => handleButtonClick('verified')}
                        >
                            <i className="fa-solid fa-check"></i>
                        </button>

                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'nearby' ? 'activeToolTip' : ''}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Nearby"
                            onClick={() => handleButtonClick('nearby')}
                        >
                            <i className="fa-solid fa-location-arrow"></i>
                        </button>

                        <button
                            className={`btn btn-light btn-sm px-2 py-1 ${activeButton === 'offers' ? 'activeToolTip' : ''}`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Offers"
                            onClick={() => handleButtonClick('offers')}
                        >
                            <i className="fa-solid fa-tags"></i>
                        </button>
                    </div>

                    {/* <!-- start button group --> */}
                    <div className="border-0 card d-inline-flex flex-row flex-wrap gap-1 p-1 rounded-3 shadow-sm ">
                        <i className={`fa-solid fa-border-all btn btn-light btn-sm px-2 py-1 ${showGrid.gridView ? 'tooltip-list-view' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Grid" onClick={handleShowGridView}></i>
                        <i className={`fa-solid fa-list btn btn-light btn-sm px-2 py-1 ${showGrid.listView ? 'tooltip-list-view' : ''}`} data-bs-toggle="tooltip" data-bs-placement="top" title="List" onClick={handleShowListView} ></i>
                    </div>
                    {/* <!-- end /. button group --> */}
                </div>
                {showGrid.gridView ?
                    <div className="row g-4 mb-5">
                        {listingData?.listings?.length > 0 && listingData?.listings?.map((el, index) => {
                            return (
                                <div key={index} className="col-sm-6 col-lg-4 col-xl-12 col-xxl-6 d-flex">
                                    {/* <!-- start listing card --> */}
                                    <div className="card rounded-3 border-0 shadow-sm w-100 flex-fill overflow-hidden card-hover flex-fill w-100 card-hover-bg">
                                        {/* <!-- start card link --> */}
                                        {/* <Link to={`/category-detail?id=${el.id}`} className="stretched-link" ></Link> */}
                                        {/* <!-- end /. card link --> */}
                                        {/* <!-- start card image wrap --> */}
                                        <div className="card-img-wrap card-image-hover overflow-hidden dark-overlay z-0 ">
                                            <img src={el.image} alt="" className="img-card object-fit-cover " style={{ width: "406px", height: "272px" }} onClick={() => { navigate(`/category-detail?id=${el.id}`) }} />
                                            <div className="bg-blur card-badge d-inline-block position-absolute start-0 text-white z-2"><i className="fa-solid fa-star"></i> Featured</div>
                                            <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-1">
                                                <a href="#" onClick={markFav} className="align-items-center bg-blur btn-icon d-flex justify-content-center rounded-circle shadow-sm text-white" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Bookmark">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        {/* <!-- end /. card image wrap --> */}
                                        <div className="d-flex flex-column h-100 position-relative p-3">
                                            <div className="align-items-center bg-primary cat-icon d-flex justify-content-center position-absolute rounded-circle text-white"><i className="fa-solid fa-shop"></i></div>
                                            <div className="align-items-center d-flex flex-wrap gap-1 text-primary card-start mb-1">
                                                {/* <!-- start ratings --> */}
                                                <i className="fa-solid fa-star"></i>
                                                {/* <!-- end /. ratings --> */}
                                                {/* <!-- start ratings counter text --> */}
                                                <span className="fw-medium text-primary"><span className="fs-6 fw-semibold me-1">{`(${el.rating})`}</span>{el.reviewCount} reviews</span>
                                                {/* <!-- end /. ratings counter text --> */}
                                            </div>
                                            {/* <!-- start card title --> */}
                                            <h4 className="fs-18 fw-semibold mb-0 cr-p" onClick={() => { navigate(`/category-detail?id=${el.id}`) }}>
                                                <span className="me-2"> {el.title}</span>
                                                {el.verified && <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                                </svg>}
                                            </h4>
                                            {/* <!-- end /. card title --> */}
                                            {/* <!-- start contact content --> */}
                                            <div className="d-flex flex-wrap gap-3 mt-2 z-1">
                                                {el?.phone && <a href={`tel:${el.phone}`} className="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" className="bi bi-telephone" viewBox="0 0 16 16">
                                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                    </svg>
                                                    <span>{el.phone}</span>
                                                </a>}

                                                {el.listing_whatsapp && <a className="d-flex gap-2 align-items-center fs-13 fw-semibold cr-p" >
                                                    <svg
                                                        onClick={() => handleClick(el)}
                                                        height="20px"
                                                        width="20px"
                                                        version="1.1"
                                                        id="Capa_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        viewBox="0 0 58 58"
                                                        fill="#000000"
                                                    >
                                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                        <g id="SVGRepo_iconCarrier">
                                                            <g>
                                                                <path
                                                                    style={{ fill: "#2CB742" }}
                                                                    d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5 S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
                                                                ></path>
                                                                <path
                                                                    style={{ fill: "#FFFFFF" }}
                                                                    d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42 c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242 c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169 c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097 c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
                                                                ></path>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <span>What's App</span>
                                                </a>}
                                                {(el.listing_lat && el.listing_lng &&
                                                    !isNaN(el.listing_lat) && !isNaN(el.listing_lng) &&
                                                    el.listing_lat.toString().toLowerCase() !== "nan" &&
                                                    el.listing_lng.toString().toLowerCase() !== "nan") &&
                                                    <a href={`https://www.google.com/maps?q=${+el.listing_lat},${+el.listing_lng}&z=15&hl=en`} target="_blank" className="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" className="bi bi-compass" viewBox="0 0 16 16">
                                                            <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                            <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                        </svg>
                                                        <span>Directions</span>
                                                    </a>}
                                            </div>
                                            {/* <!-- end contact content --> */}
                                        </div>
                                    </div>
                                    {/* <!-- end /. listing card --> */}
                                </div>
                            )
                        })
                        }
                    </div> :
                    <div className="mb-4">
                        {/* <!-- start card list --> */}
                        {listingData?.listings?.length > 0 && listingData?.listings?.map((el, index) => {
                            return (
                                <div key={index} className="card border-0 shadow-sm overflow-hidden rounded-4 mb-4 card-hover card-hover-bg">
                                    {/* <Link to={} className="stretched-link" ></Link> */}
                                    <div className="card-body p-0">
                                        <div className="g-0 row">
                                            <div className="col-lg-5 col-md-5 col-xl-12 col-xxl-5 position-relative cr-p" onClick={() => { navigate(`/category-detail?id=${el.id}`) }}>
                                                <div className="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                                                    {/* <!-- start image --> */}
                                                    <img src={el.image} alt="" className="img-card object-fit-cover" style={{ width: "272px", height: "272px" }} />
                                                    {/* <!-- end /. image --> */}
                                                    <div className="bg-blur card-badge d-inline-block position-absolute start-0 text-white z-2"><i className="fa-solid fa-star me-1"></i>Featured</div>
                                                    {/* <div className="bg-blur card-badge d-inline-block position-absolute start-0 text-white z-2">$100 off $399: eblwc</div> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-xl-12 col-xxl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                                                <div className="d-flex flex-column h-100">
                                                    <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-0" >
                                                        <a href="#" className="btn-icon shadow-sm d-flex align-items-center justify-content-center text-primary bg-light rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Bookmark">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" onClick={markFav} viewBox="0 0 16 16">
                                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                    <div className="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mb-2">
                                                        {/* <!-- start ratings --> */}
                                                        <i className="fa-solid fa-star"></i>
                                                        {/* <!-- end /. ratings --> */}
                                                        {/* <!-- start rating counter text --> */}
                                                        <span className="fw-medium text-primary"><span className="fs-6 fw-semibold me-1">{`(${el.rating})`}</span>{el.reviewCount} reviews</span>
                                                        {/* <!-- end /. rating counter text --> */}
                                                    </div>
                                                    {/* <!-- start card title --> */}
                                                    <h4 className="fs-18 fw-semibold mb-0 cr-p" onClick={() => { navigate(`/category-detail?id=${el.id}`) }}>
                                                        <span className="me-2"> {el.title}</span>
                                                        {el.verified && <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-patch-check-fill text-success" viewBox="0 0 16 16">
                                                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                                        </svg>}
                                                    </h4>
                                                    {/* <!-- end /. card title --> */}

                                                    {/* <!-- start card description --> */}
                                                    <div className="mt-3 mb-3 d-flex cr-p" onClick={() => { navigate(`/category-detail?id=${el.id}`) }}>
                                                        <span className="me-2">
                                                            <svg
                                                                height="20"
                                                                width="20"
                                                                viewBox="0 0 512 512"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                fill="#7d7d7d"
                                                                stroke="#7d7d7d"
                                                            >
                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <title>about</title>
                                                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g id="about-white" fill="#7d7d7d" transform="translate(42.666667, 42.666667)">
                                                                            <path
                                                                                d="M213.333333,0 C95.51296,0 0,95.51168 0,213.333333 C0,331.153707 95.51296,426.666667 213.333333,426.666667 C331.154987,426.666667 426.666667,331.153707 426.666667,213.333333 C426.666667,95.51168 331.154987,0 213.333333,0 Z M213.333333,384 C119.227947,384 42.6666667,307.43872 42.6666667,213.333333 C42.6666667,119.227947 119.227947,42.6666667 213.333333,42.6666667 C307.44,42.6666667 384,119.227947 384,213.333333 C384,307.43872 307.44,384 213.333333,384 Z M240.04672,128 C240.04672,143.46752 228.785067,154.666667 213.55008,154.666667 C197.698773,154.666667 186.713387,143.46752 186.713387,127.704107 C186.713387,112.5536 197.99616,101.333333 213.55008,101.333333 C228.785067,101.333333 240.04672,112.5536 240.04672,128 Z M192.04672,192 L234.713387,192 L234.713387,320 L192.04672,320 L192.04672,192 Z"
                                                                                id="Shape"
                                                                            ></path>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div>{trimText(el.description && el.description?.replace(/<[^>]+>/g, ''))}</div>
                                                    </div>
                                                    {/* <!-- end /. card description --> */}

                                                    <p className="d-flex align-items-start">
                                                        <span className="me-2">
                                                            <svg viewBox="0 0 1024 1024" height="20px" width="20px" fill="#7d7d7d" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#7d7d7d"><g id="SVGRepo_bgCarrier" strokewidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>
                                                        </span>
                                                        {el.listing_address}
                                                    </p>

                                                    {/* <!-- start contact content --> */}
                                                    <div className="d-flex flex-wrap gap-2 mt-auto z-0">
                                                        {el.phone && <a href={`tel:${el.phone}`} className="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" className="bi bi-telephone" viewBox="0 0 16 16">
                                                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                            </svg>
                                                            <span>{el.phone}</span>
                                                        </a>}

                                                        {el.listing_whatsapp && <div className="d-flex gap-2 align-items-center fs-13 fw-semibold" style={{ cursor: "pointer" }} onClick={() => handleClick(el)}>
                                                            <svg
                                                                height="20px"
                                                                width="20px"
                                                                version="1.1"
                                                                id="Capa_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                viewBox="0 0 58 58"
                                                                fill="#000000"
                                                            >
                                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                    <g>
                                                                        <path
                                                                            style={{ fill: "#2CB742" }}
                                                                            d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5 S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
                                                                        ></path>
                                                                        <path
                                                                            style={{ fill: "#FFFFFF" }}
                                                                            d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42 c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242 c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169 c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097 c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
                                                                        ></path>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                            <span>What's App</span>
                                                        </div>}

                                                        {(el.listing_lat && el.listing_lng &&
                                                            !isNaN(el.listing_lat) && !isNaN(el.listing_lng) &&
                                                            el.listing_lat.toString().toLowerCase() !== "nan" &&
                                                            el.listing_lng.toString().toLowerCase() !== "nan") &&
                                                            <a href={`https://www.google.com/maps?q=${+el.listing_lat},${+el.listing_lng}&z=15&hl=en`} target="_blank" className="d-flex gap-2 align-items-center fs-13 fw-semibold">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9b9b9b" className="bi bi-compass" viewBox="0 0 16 16">
                                                                    <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                                                    <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                                                                </svg>
                                                                <span>Directions</span>
                                                            </a>}
                                                    </div>
                                                    {/* <!-- end contact content --> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <!-- end /. card list --> */}
                    </div>
                }

                {/* <!-- start pagination --> */}
                <AllServicesPagination listingData={listingData}
                    filters={filters} setFilters={setFilters} />
                {/* <!-- end /. pagination --> */}
            </div>
            {/* <!-- end /. items content --> */}
            {/* <!-- start map content --> */}
            <div className={`map-content ${isMapContentOpened ? 'opened' : ''} ${isSidebarActive ? 'active' : ''} ${!isListingVisible ? 'full-width' : ''}`}>

                <div className="d-flex gap-2 position-absolute start-0 top-0 mt-4 ms-4 d-none d-xl-flex">
                    {/* Filter Toggle Button */}
                    <button
                        type="button"
                        className="sidebarCollapse collapseText btn btn-primary align-items-center gap-2 hstack filters-text gap-1"
                        onClick={handleSidebarCollapseClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-sort-alpha-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"></path>
                            <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zm-8.46-.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707V13.5z"></path>
                        </svg>
                        <span>{isSidebarActive ? 'Show filters' : 'Hide filters'}</span>
                    </button>

                    {/* Listings Toggle Button */}
                    <button
                        type="button"
                        className="sidebarCollapse collapseText btn btn-primary align-items-center gap-2 hstack filters-text gap-1"
                        onClick={handleListingVisibilityClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-sort-alpha-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"></path>
                            <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zm-8.46-.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707V13.5z"></path>
                        </svg>
                        <span>{isListingVisible ? 'Hide Listings' : 'Show Listings'}</span>
                    </button>
                </div>

                <div className="d-flex inner-wrap--top p-3 position-absolute start-0 top-0 w-100 d-xl-none">
                    {/* <!-- map close icon --> */}
                    <div className="align-items-center d-flex flex-shrink-0 justify-content-center map-close-icon rounded-circle" onClick={handleMapCollapseClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                        </svg>
                    </div>
                    {/* <!-- map search input --> */}
                    <div className="search-select-input has-icon has-icon-y position-relative flex-grow-1 ms-2">
                        {/* <!-- input --> */}
                        <input className="form-control" type="text" placeholder="What are you looking for?" />
                        {/* <!-- icon --> */}
                        <svg className="form-icon-start position-absolute top-50 bi bi-pin-map-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
                            <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
                        </svg>
                        {/* <!-- icon --> */}
                        <svg className="form-icon-end position-absolute top-50 bi bi-crosshair" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9b9b9b" viewBox="0 0 16 16">
                            <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7.001 7.001 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7.001 7.001 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7.001 7.001 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7.001 7.001 0 0 0 8.5 1.018V.5Zm-6.48 7A6.001 6.001 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6.001 6.001 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6.002 6.002 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6.001 6.001 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1h-.48ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                        </svg>
                        {/* <!-- select --> */}
                        <select className="input-select position-absolute top-50">
                            <option selected>0.5 km</option>
                            <option value="1">1 km</option>
                            <option value="2">5 km</option>
                            <option value="3">10 km</option>
                        </select>
                    </div>
                </div>
                {/* <!-- filter button --> */}
                <div className="all-filters-wrap bottom-0 mb-5 position-absolute start-0 text-center w-100 d-xl-none">
                    <div className="align-items-center all-filters d-inline-flex fw-semibold gap-2 justify-content-center px-4 py-3 rounded-pill">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-sort-alpha-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"></path>
                            <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zm-8.46-.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707V13.5z"></path>
                        </svg>
                        <span>All filters</span>
                    </div>
                </div>
                {/* <!-- start map --> */}
                <Map menuItems={[]} userLocation={userLocation} mapMarkers={listingData?.listings?.length ? listingData?.listings : []} />
                {/* <!-- end /. map --> */}
            </div>
            {/* <!-- end /. map content --> */}
        </div>

    </>)
}

export default AllServices;
