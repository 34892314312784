import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    navbarData: {},
    cities: [],
}

export const navbarDataSlice = createSlice({
    name: 'allCategories',
    initialState,
    reducers: {
        storeValue: (state, action) => {
            state.navbarData = action.payload
        },
        setCityData: (state, action) => {
            state.cities = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { storeValue, setCityData } = navbarDataSlice.actions

export default navbarDataSlice.reducer