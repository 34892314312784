import React, { useEffect, useState } from "react";
import Map from "../common/Map/map";
import Places from "./places";
import Categories from "./categories";
import Services from "./services";
import Blogs from "./blogs";
import { HTTP_STATUS_CODES } from "../../constants/ApiUrl";
import { serviceGetCategories } from "../../services/home.service";
import { useSelector } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { serviceListingsData } from "../../services/index.service";
import Loader from "../common/Loader";

const Home = () => {

  const reduxData = useSelector((state) => state.allCategories.navbarData);

  const [userLocation, setUserLocation] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listingData, setLisitingData] = useState([]);

  const [showAdvertisment, setShowAdvertisment] = useState({
    advertisementMiddle: true,
    advertisementBotton: true,
  });
  const [mapFilters, setMapFilters] = useState({
    menuitems: "All Services",
    searchText: "",
    location: "",
  })

  useEffect(() => {
    getFooteritems();
    getLocation();
    getCategories();
  }, [])

  const getFooteritems = () => {
    try {
      serviceGetCategories().then((res) => {
        if (res.status === HTTP_STATUS_CODES.OK) {
          setCategories(res.data.categories);
        }
      })
    } catch (error) {
      console.log(error)
      setCategories([]);
    }
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {

          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    } else {
      console.error("Geolocation not supported");
    }
  };

  const getCategories = async () => {
    try {
      const params = {
        is_pgtw_active: false
      };

      setLoading(true)
      serviceListingsData(params)
        .then((response) => {
          if (response.status === HTTP_STATUS_CODES.OK) {
            setLoading(false);
            setLisitingData(response.data);
          }
        })
        .catch((err) => {
          setLoading(false);
        })

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {/* <!-- start hero header (map) --> */}
      <Loader loading={loading} />
      <div className="align-items-end d-flex hero-header-map position-relative">
        <Map
          userLocation={userLocation}
          menuItems={reduxData?.menu_items || []}
          mapFilters={mapFilters}
          setMapFilters={setMapFilters}
          mapMarkers={listingData?.listings?.length ? listingData?.listings : []}
        />
      </div>
      {/* <!-- end /. hero header (map) --> */}

      {/* <!-- start categories section --> */}
      <Categories category={reduxData?.menu_items || []} />
      {/* <!-- end /. categories section --> */}

      <div className="py-5 border-top position-relative overflow-hidden">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
              {/* <!-- start section header --> */}
              <div
                className="section-header text-center mb-5 aos-init aos-animate"
                data-aos="fade-down"
              >
                {/* <!-- start subtitle --> */}
                <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                  Best Way
                </div>
                {/* <!-- end /. subtitle --> */}
                {/* <!-- start title --> */}
                <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                  Find Your Dream Place The Best Way
                </h2>
                {/* <!-- end /. title --> */}
                {/* <!-- start description --> */}
                <div className="sub-title fs-16">
                  Discover exciting categories.{" "}
                  <span className="text-primary fw-semibold">
                    Find what you’re looking for.
                  </span>
                </div>
                {/* <!-- end /. description --> */}
              </div>
              {/* <!-- end /. section header --> */}
            </div>
          </div>
          <div className="bg-no-repeat numbers-wrapper">
            <div className="row g-4">
              <div className="col-md-4">
                <div
                  className="mx-xl-4 number-wrap text-center aos-init"
                  data-aos="fade"
                  data-aos-delay="300"
                >
                  <div className="align-items-center bg-primary d-flex font-caveat fs-1 justify-content-center mb-4 mx-auto number-circle rounded-circle text-white">
                    1
                  </div>
                  <h4>Input your location to start looking for landmarks.</h4>
                  <p className="fs-15 m-0 opacity-75">
                    orem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra vitae quam integer semper.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="mx-xl-4 number-wrap text-center second aos-init"
                  data-aos="fade"
                  data-aos-delay="400"
                >
                  <div className="align-items-center bg-primary d-flex font-caveat fs-1 justify-content-center mb-4 mx-auto number-circle rounded-circle text-white">
                    2
                  </div>
                  <h4>Make an appointment at the place you want to visit.</h4>
                  <p className="fs-15 m-0 opacity-75">
                    orem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra vitae quam integer.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="mx-xl-4 number-wrap text-center aos-init"
                  data-aos="fade"
                  data-aos-delay="500"
                >
                  <div className="align-items-center bg-primary d-flex font-caveat fs-1 justify-content-center mb-4 mx-auto number-circle rounded-circle text-white">
                    3
                  </div>
                  <h4>Visit the place and enjoy the experience.</h4>
                  <p className="fs-15 m-0 opacity-75">
                    orem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pharetra vitae quam integer aenean.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Favourite Places section */}
      <Places />
      {/* Favourite Places section */}

      {/* <!-- start listings carousel --> */}
      {/* ***************************************************************** */}
      {/* <!-- end listings carousel --> */}

      {/* advertisement Middle */}
      {showAdvertisment.advertisementMiddle && (
        <div className="d-flex justify-content-center m-3">
          <div
            style={{
              width: "80%",
              maxWidth: "1200px",
              padding: "0 15px",
              display: "flex",
              justifyContent: "",
            }}
          >
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="/assets/images/adbanner1.jpg"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src="/assets/images/adbanner2.jpg"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* <!-- All serices section --> */}
      <Services categories={categories} />
      {/* <!-- end /. All services section --> */}

      {/* AdvertimJ    ent section */}
      {showAdvertisment.advertisementBotton && (
        <div className="ad-wrapper py-5 border-top position-relative overflow-hidden">
          <div className="ad-container">
            <img src="/assets/images/ads2.jpg" alt="Advertisement" />
            <span className="ad-label">Ad</span>
          </div>
        </div>
      )}
      {/* Advertiment section */}

      {/* Blogs section */}
      <Blogs />
      {/* Blogs section */}
    </>
  );
};

export default Home;
