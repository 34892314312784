import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import CarouselListingCard from '../common/Cards/carouselCard';

const StartListingCarousel = ({ cardsData }) => {

  const options = {
    loop: true,
    margin: 24,
    nav: false,
    // navText: [&#x27; next&#x27;,&#x27; prev & #x27;],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      992: {
        items: 3,
      },
      // 1200: {
      //   items: 4,
      // },
    },
  };


  return (
    <>
      <div className="position-relative overflow-hidden ">
        <div className="container py-4">
          <OwlCarousel
            className="listings-carousel owl-carousel owl-theme"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            {...options}
          >
            {/* Listing cards go here */}
            {cardsData?.map((el, index) => {
              return <div key={index}>
                <CarouselListingCard
                  image={el.service_image}
                  title={el.listing_name}
                  listingId={el.listing_id}
                />
              </div>
            })}
          </OwlCarousel>
        </div>
      </div>
    </>
  )
}

export default StartListingCarousel;