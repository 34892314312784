import React from 'react';

const StarRating = ({ averageRating, reviewCount }) => {
    const totalStars = 5;
    const safeRating = Math.min(averageRating, totalStars); // Limit rating to max 5
    const fullStars = Math.floor(safeRating);
    const halfStar = safeRating % 1 >= 0.5;
    const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0);

    return (
        <div className="d-flex align-items-center gap-2 ms-auto">
            <div className="d-flex align-items-center text-primary rating-stars">
                {/* Render full stars */}
                {[...Array(fullStars)].map((_, index) => (
                    <i key={index} className="fa-star-icon"></i>
                ))}

                {/* Render half star if needed */}
                {halfStar && <i className="fa-star-icon half"></i>}

                {/* Render empty stars */}
                {[...Array(emptyStars)].map((_, index) => (
                    <i key={index} className="fa-star-icon none"></i>
                ))}
            </div>

            {/* Counter text for reviews */}
            <span className="fw-medium text-primary">
                <span className="fs-6 fw-semibold me-1">
                    {safeRating ? `(${safeRating.toFixed(1)})` : '(0)'}
                </span>
                {reviewCount && <small>{reviewCount ? reviewCount : 0} reviews</small>}
            </span>
        </div>
    );
};

export default StarRating;