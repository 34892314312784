import { useState } from "react";
import { Link } from "react-router-dom";
import { serviceLogin } from "../../services/index.service";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import { LOGIN_SCHEMA } from "../../schema/register.schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LOGIN_SCHEMA),
        mode: "onChange",
    });



    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const loginService = (userData) => {
        try {
            setLoading(true)
            serviceLogin(userData).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success(response.data.message, { toastId: "login" });
                    localStorage.setItem("token", JSON.stringify(response.data.tokens))
                }
            }).catch((err) => {
                console.log(err, "error")
                toast.error(err.response.data.message)
                setLoading(false);
            })
        } catch (error) {
            console.log(error, "error")
            setLoading(false);
        }
    }


    return (
        <>
            <Loader loading={loading} />
            <div className="p-3 p-sm-5">
                <div className="row g-4 g-xl-5 justify-content-between">
                    <div className="col-xl-5 d-flex justify-content-center">
                        <div className="authentication-wrap overflow-hidden position-relative text-center text-sm-start my-5">
                            {/* <!-- Start Header Text --> */}
                            <div className="mb-5">
                                <h2 className="display-6 fw-semibold mb-3">Welcome back! Please <span className="font-caveat text-primary">Sign in</span> to continue.</h2>
                                <p className="mb-0">Unlock a world of exclusive content, enjoy special offers, and be the first to dive into exciting news and updates by joining our community!</p>
                            </div>
                            {/* <!-- /.End Header Text --> */}

                            <p className="mb-0">We won't post anything without your permission and your personal details are kept private</p>

                            {/* <!-- Start Divider --> */}
                            <div className="align-items-center d-flex my-5">
                                <hr className="flex-grow-1 m-0" /> <span className="fs-16 fw-bold px-3 text-dark">Or</span>
                                <hr className="flex-grow-1 m-0" />
                            </div>
                            {/* <!-- /.End Divider --> */}

                            {/* <!-- Start Form Group --> */}
                            <form className="register-form" onSubmit={handleSubmit(loginService)}>
                                <div className="form-group mb-4">
                                    <label className="required">User Name </label>
                                    <input
                                        type="text"
                                        name="username"
                                        autoComplete="off"  // Prevent autofill
                                        {...register("username")}
                                        className={`form-control ${errors?.username?.message ? "is-invalid" : ""}`}
                                    />
                                    {errors?.username && (
                                        <div className="invalid-feedback text-start">
                                            {errors.username.message}
                                        </div>
                                    )}
                                </div>
                                {/* <!-- /.End Form Group --> */}
                                {/* <!-- Start Form Group --> */}
                                <div className="form-group mb-4">
                                    <label className="required">Password</label>
                                    <input
                                        id="password"
                                        name="password"
                                        autoComplete="new-password"
                                        type={showPassword ? "text" : "password"}
                                        {...register("password")}
                                        className={`form-control ${errors?.password?.message ? "is-invalid" : ""}`}
                                    />
                                    {errors?.password && (
                                        <div className="invalid-feedback text-start" >
                                            {errors?.password?.message}
                                        </div>
                                    )}
                                </div>
                                {/* <!-- /.End Form Group --> */}
                                {/* <!-- Start Button --> */}
                                <button className="btn btn-primary btn-lg w-100" type="submit">Sign In</button>
                                {/* <!-- End Button --> */}
                            </form>
                            {/* <!-- Start Bottom Text --> */}
                            <div className="bottom-text text-center mt-4"> Don't have an account? <Link to="/registration" className="fw-medium text-decoration-underline">Sign Up</Link>
                                <br /> Remind <Link to="/forgot-password" className="fw-medium text-decoration-underline">Password</Link>
                            </div>
                            {/* <!-- /.End Bottom Text --> */}
                        </div>
                    </div>
                    <div className="col-xl-7 d-none d-xl-block">
                        <div className="background-image bg-light d-flex flex-column h-100 justify-content-center p-5 rounded-4" data-image-src="assets/images/lines.svg">
                            <div className="py-5 text-center">
                                <div className="mb-5">
                                    <h2 className="fw-semibold">Effortlessly organize your<br /> workspace with ease.</h2>
                                    <p>It is a long established fact that a reader will be distracted by the readable<br /> content of a page when looking at its layout. </p>
                                </div>
                                <img src="assets/images/png-img/login.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;