import React from "react";
import CategoryCard from "../common/Cards/categoryCard";

const Categories = ({ category }) => {
  return (
    <>
      <div className="py-5 bg-light rounded-4 mx-3 mt-3">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
              {/* <!-- start section header --> */}
              <div
                className="section-header text-center mb-5"
                data-aos="fade-down"
              >
                {/* <!-- start subtitle --> */}
                <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                  Services
                </div>
                {/* <!-- end /. subtitle --> */}

                {/* <!-- start title --> */}
                <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                  Popular Services near you
                </h2>
                {/* <!-- end /. title --> */}
                {/* <!-- start description --> */}
                <div className="sub-title fs-16">
                  Discover exciting services.
                  <span className="text-primary fw-semibold">
                    Find what you’re looking for.
                  </span>
                </div>
                {/* <!-- end /. description --> */}
              </div>
              {/* <!-- end /. section header --> */}
            </div>
          </div>
          <div className="row g-3 g-lg-4">
            {category.map((el, index) => {
              return (
                <CategoryCard
                  key={index}
                  lable={el.name}
                  path={el.url}
                  icon={el.icon}
                  buttonText={"Explore Now"}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
