import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { serviceOtpVerify } from "../../services/index.service";
import Loader from "../common/Loader";
import { toast } from "react-toastify";

const OtpVerification = () => {

    // State for storing OTP digits
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [loading, setLoading] = useState(false)


    const location = useLocation();
    const navigate = useNavigate();

    // Extract query parameters directly
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");


    // Handle input change
    const handleChange = (event, index) => {
        const { value } = event.target;

        // Only allow one digit per input
        if (/^\d$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move focus to the next input
            if (value && index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    // Handle backspace to move focus to the previous input
    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && otp[index] === "") {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };


    const otpVerifyService = () => {
        try {
            setLoading(true)
            serviceOtpVerify({
                otp: otp.join(''),
                email: email
            }).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success(response.data.message, { toastId: "otp_verfied" });
                    navigate('/login');
                }
            }).catch((err) => {
                console.log(err, "error")
                setLoading(false);
            })
        } catch (error) {
            console.log(error, "error")
            setLoading(false);
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <div class="p-3 p-sm-5">
                <div class="row g-4 g-xl-5 justify-content-between align-items-center">
                    <div class="col-xl-5 d-flex justify-content-center">
                        <div class="authentication-wrap overflow-hidden position-relative text-center text-sm-start my-5">
                            {/* <!-- Start Header Text --> */}
                            <div class="mb-5">
                                <h2 class="display-6 fw-semibold mb-3">Two factor <span class="font-caveat text-primary">authentication</span></h2>
                                {/* <!-- <p class="mb-0">Fill with your mail to receive instructions on how to reset your password.</p> --> */}
                                <p class="text-muted mb-0">We have to send a code to <span class="fw-medium text-dark">{email}</span></p>
                            </div>
                            {/* <!-- /.End Header Text --> */}
                            {/* <form> */}
                                <p class="mb-2 text-start">Enter the code we have sent you:</p>
                                {/* <!-- Start Input --> */}
                                <div className="d-flex justify-content-between gap-1 gap-sm-3 mb-2">
                                    {otp.map((value, index) => (
                                        <input
                                            key={index}
                                            id={`otp-input-${index}`}
                                            type="text"
                                            maxLength="1"
                                            className="form-control verification-form text-center"
                                            value={value}
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            required
                                        />
                                    ))}
                                </div>
                                {/* <!-- /.End Input --> */}
                                {/* <div class="d-sm-flex justify-content-between small mb-4"> <span>Don't get a code?</span> <a href="#" class="btn btn-sm btn-link p-0 text-decoration-underline mb-0">Click to resend</a> </div> */}
                                {/* <!-- Start Button --> */}
                                <button class="btn btn-primary btn-lg w-100" onClick={otpVerifyService}>Verify and Process</button>
                                {/* <!-- End Button --> */}
                            {/* </form> */}
                        </div>
                    </div>
                    <div class="col-xl-7 d-none d-xl-block">
                        <div class="background-image bg-light d-flex flex-column h-100 justify-content-center p-5 rounded-4" data-image-src="assets/images/lines.svg">
                            <div class="py-5 text-center">
                                <div class="mb-5">
                                    <h2 class="fw-semibold">Effortlessly organize your<br /> workspace with ease.</h2>
                                    <p>It is a long established fact that a reader will be distracted by the readable<br /> content of a page when looking at its layout. </p>
                                </div>
                                <img src="assets/images/png-img/login.png" alt="" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OtpVerification;