import { Link } from "react-router-dom";

const CategoryCard = (props) => {
    const { path, lable, buttonText, icon } = props
    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 d-flex">
                <div className="border-0 card card-hover company-card flex-fill rounded-3 w-100">
                    {/* <!-- start card link --> */}
                    <Link
                        to={path}
                        className="stretched-link"
                    ></Link>
                    {/* <!-- end /. card link --> */}
                    <div className="card-body d-flex flex-column">
                        <div className="text-end mb-4 text-primary">
                            {/* <!-- Start Image --> */}
                            <i className={icon}></i>
                            {/* <!-- /. End Image --> */}
                        </div>
                        <div className="mt-auto">
                            {/* <!-- Start Title --> */}
                            <h5 className="mb-2">{lable}</h5>
                            {/* <!-- End Title --> */}
                            {/* <!-- start link --> */}
                            <div className="small mt-2 d-flex align-items-center gap-2 fw-medium text-primary">
                                <span>{buttonText}</span>
                                <i className="fa-solid fa-arrow-up-right-from-square fs-12"></i>
                            </div>
                            {/* <!-- end /. link --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryCard; 