import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./components/Home/index";
import Layout from "./components/common/Layout/layout";
import AllServices from "./components/services/all-services";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import About from "./components/common/about";
import Contact from "./components/common/contact";
import ForgotPassword from "./components/auth/forgotPassword";
import ResetPassword from "./components/auth/resetPassword";
import OtpVerification from "./components/auth/otpVerification";
import AuthLayout from "./components/common/Layout/authLayout";
import CategoryDetail from "./components/services/category-detail";
import { useEffect } from "react";
import { serviceGetCities } from "./services/index.service";
import { HTTP_STATUS_CODES } from "./constants/ApiUrl";
import { useDispatch } from "react-redux";
import { setCityData } from "./store/slice/slices";


const App = () => {

  let authToken = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    getCitiesList();
  }, [])

  const getCitiesList = () => {
    try {
      serviceGetCities().then((res) => {
        if (res.status === HTTP_STATUS_CODES.OK) {
          dispatch(setCityData(res.data.cities))
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Routes>
        <Route path="/" element={authToken ? <AuthLayout /> : <Layout />}>
          <Route index element={<Navigate to="/home" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/all-category" element={<AllServices />} />
          <Route path="/category-detail" element={<CategoryDetail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/user/password-reset-confirm" element={<ResetPassword />} />
          <Route path="/otp-verification" element={<OtpVerification />} />

        </Route>
      </Routes>
    </>
  );
}

export default App;
