import { Link } from "react-router-dom";

const CarouselListingCard = ({ image, title, description,listingId }) => (
  <div className="d-flex flex-wrap align-items-stretch">
    <div className="card rounded-3 w-100 flex-fill overflow-hidden" style={{ height: "389px" }}>
      <Link to={`/category-detail?id=${listingId}`}>
        <div className="card-img-wrap card-image-hover overflow-hidden">
          <img src={image} alt="" className="img-fluid" />
        </div>
        <div className="d-flex flex-column h-100 position-relative p-4">
          <h4 className="fs-5 fw-semibold mb-0">{title}</h4>
          <div className="align-items-center d-flex flex-wrap gap-1 text-primary card-start">
            {/* <i className="fa-solid fa-star"></i> */}
            <span className="fw-medium text-primary">
              <span className="fs-6 fw-semibold me-1">
                <small>{description}</small>
              </span>
            </span>
          </div>
        </div>
      </Link>
    </div>
  </div>
);

export default CarouselListingCard;