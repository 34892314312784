import $ from 'jquery';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const SearchBar = ({ reduxData, handleChangeFilters, handleSidebarCollapseClick, handleMapCollapseClick, filters, getCategories }) => {

    const [isMobile, setIsMobile] = useState(false);

    const locationSelectRef = useRef(null);
    const categorySelectRef = useRef(null);

    const citiesData = useSelector((state) => state.allCategories.cities || []);

    useEffect(() => {
        const initSelect2 = (ref, placeholder) => {
            if (ref.current) {
                $(ref.current).select2({
                    theme: "bootstrap-5",
                    width: '100%',
                    placeholder: placeholder,
                }).on('select2:select', handleChangeFilters);
            }
        };

        initSelect2(locationSelectRef, 'Select Location');
        initSelect2(categorySelectRef, 'Select Category'); // Add this if you have a category select

        return () => {
            const destroySelect2 = (ref) => {
                if (ref.current && $(ref.current).data('select2')) {
                    $(ref.current).off('select2:select').select2('destroy');
                }
            };

            destroySelect2(locationSelectRef);
            destroySelect2(categorySelectRef); // Add this if you have a category select
        };
    }, []);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);


    const debouncedHandleChangeFilters = useCallback(
        debounce((event) => {
            handleChangeFilters(event);
        }, 500), // 300ms delay
        []
    );

    const styles = {
        zIndex: 3,
        position: isMobile ? undefined : 'sticky',
        top: isMobile ? '78px' : '82px',
        backgroundColor: "#ffff",
    };

    return (
        <div className={`border-bottom border-top p-3 p-xl-0 search-bar ${isMobile ? '' : 'sticky-top'}`} style={styles}>
            <div className="row g-3 g-xl-0">
                {/* <!-- search bar title --> */}
                <div className="col-12 d-xl-none">
                    <div className="collapse show" id="CollapseText">
                        <h2 className="fw-semibold text-center search-bar-title mb-0">Find what<br /> you <span className="font-caveat text-primary">want</span></h2>
                    </div>
                </div>
                <div className="col-md-8 col-lg-5 col-xl-6">
                    <div className="search-select-input has-icon has-icon-y position-relative">
                        {/* <!-- input --> */}
                        <input className="form-control" type="text" placeholder="What are you looking for?" name="searchText" onChange={debouncedHandleChangeFilters} />
                        {/* <!-- icon --> */}
                        <svg className="form-icon-start position-absolute top-50 bi bi-pin-map-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
                            <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
                        </svg>
                        {/* <!-- icon --> */}
                        <svg className="form-icon-end position-absolute top-50 bi bi-crosshair" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9b9b9b" viewBox="0 0 16 16">
                            <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7.001 7.001 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7.001 7.001 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7.001 7.001 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7.001 7.001 0 0 0 8.5 1.018V.5Zm-6.48 7A6.001 6.001 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6.001 6.001 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6.002 6.002 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6.001 6.001 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1h-.48ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                        </svg>
                        {/* <!-- select --> */}
                        <select className="input-select position-absolute top-50" name="distance" onChange={handleChangeFilters}>
                            <option >0.5 km</option>
                            <option value="1">1 km</option>
                            <option selected value="5">5 km</option>
                            <option value="10">10 km</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-2 col-lg-2 col-xl-3">
                    {/* <!-- Start Search Select --> */}
                    <div className="search-select has-icon position-relative z-0">
                        <select ref={locationSelectRef} className="select2 form-select" value={filters ? filters?.location : ''} aria-label="Default select example" name="location" onChange={handleChangeFilters}>
                            <option selected>Location</option>
                            {citiesData && citiesData.map((el, index) => {
                                return <option key={index} value={el.city_id}>{el.city_name}</option>
                            })}
                        </select>
                        <svg className="form-icon-start position-absolute top-50 search-icon z-1 bi bi-geo-alt"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            viewBox="0 0 16 16">
                            <path
                                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                    </div>
                    {/* <!-- /.End Search Select --> */}
                </div>
                <div className="col-md-2 col-lg-2 col-xl-3 d-lg-block z-0">
                    {/* <!-- Start Search Select --> */}
                    <div className="search-select has-icon position-relative">
                        <select ref={categorySelectRef} className="select2 form-select" name="category" aria-label="Default select example">
                            {/* <option selected>All Categories</option> */}
                            {/* <!-- Start Form Check --> */}
                            <option selected>Select Category</option>
                            {reduxData?.categories?.length > 0 && reduxData?.categories?.map((el, index) => {
                                return (
                                    <option key={index}>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="checkbox" value="" id={`skillsOne${index}`} />
                                            <label className="form-check-label" htmlFor={`skillsOne${index}`}>{el.category_name} </label>
                                        </div>
                                    </option>
                                )
                            })}
                            {/* <!-- End Form Check --> */}
                        </select>
                        {/* <!-- <i className="fa-solid fa-sack-dollar fs-18 search-icon"></i> --> */}
                        <svg className="form-icon-start position-absolute top-50 search-icon z-1 bi bi-app-indicator" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z" />
                            <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        </svg>
                    </div>
                    {/* <!-- /.End Search Select --> */}
                </div>
                <div className="col-lg-3 col-md-4 col-mg-3 d-xl-none gap-3 gap-md-2 hstack justify-content-center z-0">
                    <a href="#" className="sidebarCollapse align-items-center d-flex justify-content-center filters-text fw-semibold gap-2" onClick={handleSidebarCollapseClick}>
                        <i className="fa-solid fa-arrow-up-short-wide fs-18"></i>
                        <span>All filters</span>
                    </a>
                    <div className="h-75 mt-auto vr d-md-none"></div>
                    <a href="#" id="mapCollapse" className="align-items-center d-flex justify-content-center filters-text fw-semibold gap-2" onClick={handleMapCollapseClick}>
                        <i className="fa-solid fa-map-location-dot fs-18"></i>
                        <span>Map</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SearchBar