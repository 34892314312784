import { Link, useNavigate } from "react-router-dom";

const Services = ({ categories }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="py-5">
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
                            {/* <!-- start section header --> */}
                            <div
                                className="section-header text-center mb-5"
                                data-aos="fade-down"
                            >
                                {/* <!-- start subtitle --> */}
                                <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                                    Services
                                </div>
                                {/* <!-- end /. subtitle --> */}
                                {/* <!-- start title --> */}
                                <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                                    Explore our more Services
                                </h2>
                                {/* <!-- end /. title --> */}
                                {/* <!-- start description --> */}
                                <div className="sub-title fs-16">
                                    Explore our more Services{" "}
                                    <span className="text-primary fw-semibold">
                                        Find what you’re looking for.
                                    </span>
                                </div>
                                {/* <!-- end /. description --> */}
                            </div>
                            {/* <!-- end /. section header --> */}
                        </div>
                    </div>

                    <div className="row g-4">
                        {/* First column (col-md-6) */}
                        <div className="col-md-6">
                            {categories[0] && (
                                <div className="card rounded-4 h-100 overflow-hidden bg-light border-0">
                                    <div className="position-relative overflow-hidden dark-overlay h-100">
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND_API_URL}${categories[0].category_image}`}
                                            className="h-100 w-100 object-fit-cover image-zoom-hover"
                                            alt="Image"
                                        />
                                    </div>
                                    <div className="align-items-center badge bg-white d-flex fs-13 fw-semibold justify-content-center position-absolute rounded-4 text-primary top-0 end-0 mt-3 me-3">
                                        {categories[0].listing_count} Listing
                                    </div>
                                    <div className="card-body py-3">
                                        <h4 className="font-caveat text-primary mb-0">
                                            {categories[0].category_name}
                                        </h4>
                                        <h5 className="mb-0 fw-semibold">
                                            <Link to={`/all-category?id=${categories[0].category_id}`} className="stretched-link">
                                                {categories[0].category_description}
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Second column (col-md-3) */}
                        <div className="col-md-3">
                            {categories[1] && (
                                <div className="card rounded-4 h-100 overflow-hidden bg-light border-0">
                                    <div className="position-relative overflow-hidden dark-overlay h-100">
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND_API_URL}${categories[1].category_image}`}
                                            className="h-100 w-100 object-fit-cover image-zoom-hover"
                                            alt="Image"
                                        />
                                    </div>
                                    <div className="align-items-center badge bg-white d-flex fs-13 fw-semibold justify-content-center position-absolute rounded-4 text-primary top-0 end-0 mt-3 me-3">
                                        {categories[1].listing_count} Listing
                                    </div>
                                    <div className="card-body py-3">
                                        <h4 className="font-caveat text-primary mb-0">{categories[1].category_name}</h4>
                                        <h5 className="mb-0 fw-semibold">
                                            <Link to={`/all-category?id=${categories[1].category_id}`} className="stretched-link">
                                                {categories[1].category_description}
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Third column (col-md-3 with two cards) */}
                        <div className="col-md-3">
                            <div className="row g-4 h-100">
                                <div className="col-12">
                                    {categories[2] && (
                                        <div className="card rounded-4 h-100 overflow-hidden bg-light border-0">
                                            <div className="position-relative overflow-hidden dark-overlay h-100">
                                                <img
                                                    src={`${process.env.REACT_APP_BACKEND_API_URL}${categories[2].category_image}`}
                                                    className="h-100 w-100 object-fit-cover image-zoom-hover"
                                                    alt="Image"
                                                />
                                            </div>
                                            <div className="align-items-center badge bg-white d-flex fs-13 fw-semibold justify-content-center position-absolute rounded-4 text-primary top-0 end-0 mt-3 me-3">
                                                {categories[2].listing_count} Listing
                                            </div>
                                            <div className="card-body py-3">
                                                <h4 className="font-caveat text-primary mb-0">
                                                    {categories[2].category_name}
                                                </h4>
                                                <h5 className="mb-0 fw-semibold">
                                                    <Link to={`/all-category?id=${categories[2].category_id}`} className="stretched-link">
                                                        {categories[2].category_description}
                                                    </Link>
                                                </h5>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    {categories[3] && (
                                        <div className="card rounded-4 h-100 overflow-hidden bg-light border-0">
                                            <div className="position-relative overflow-hidden dark-overlay h-100">
                                                <img
                                                    src={`${process.env.REACT_APP_BACKEND_API_URL}${categories[3].category_image}`}
                                                    className="h-100 w-100 object-fit-cover image-zoom-hover"
                                                    alt="Image"
                                                />
                                            </div>
                                            <div className="align-items-center badge bg-white d-flex fs-13 fw-semibold justify-content-center position-absolute rounded-4 text-primary top-0 end-0 mt-3 me-3">
                                                {categories[3].listing_count} Listing
                                            </div>
                                            <div className="card-body py-3">
                                                <h4 className="font-caveat text-primary mb-0">
                                                    {categories[3].category_name}
                                                </h4>
                                                <h5 className="mb-0 fw-semibold">
                                                    <Link to={`/all-category?id=${categories[3].category_id}`} className="stretched-link">
                                                        {categories[3].category_description}
                                                    </Link>
                                                </h5>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-3">
                        <button
                            className="btn"
                            style={{
                                backgroundColor: "#F84525",
                                color: "#fff",
                                borderRadius: "2rem",
                                fontWeight: "600",
                            }}
                            onClick={() => {
                                navigate('/all-category')
                            }}
                        >
                            View All Services
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Services;