import React from "react";
import { Link } from "react-router-dom";

const Places = () => {
  return (
    <>
      <div className="py-5 position-relative overflow-hidden bg-light rounded-4 mx-3 mt-3">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
              {/* <!-- start section header --> */}
              <div className="section-header text-center mb-5" data-aos="fade-down">
                {/* <!-- start subtitle --> */}
                <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                  Places
                </div>
                {/* <!-- end /. subtitle --> */}
                {/* <!-- start title --> */}
                <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                  Discover your favourite place
                </h2>
                {/* <!-- end /. title --> */}
                {/* <!-- start description --> */}
                <div className="sub-title fs-16">
                  Discover exciting categories.{" "}
                  <span className="text-primary fw-semibold">
                    Find what you’re looking for.
                  </span>
                </div>
                {/* <!-- end /. description --> */}
              </div>
              {/* <!-- end /. section header --> */}
            </div>
          </div>
          <div className="row g-4">
            <div className="col-lg-6">
              {/* <!-- start card list --> */}
              <div className="card border-0 shadow-sm overflow-hidden rounded-4 card-hover">
                <Link to="#" className="stretched-link"></Link>
                <div className="card-body p-0">
                  <div className="g-0 row">
                    <div className="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                      <div className="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                        {/* <!-- start image --> */}
                        <img
                          src="/assets/images/place/01.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover"
                        />
                        {/* <!-- end /. image --> */}
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          <i className="fa-solid fa-star me-1"></i>Featured
                        </div>
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          $100 off $399: eblwc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-1">
                          <Link
                            to="#"
                            className="btn-icon shadow-sm d-flex align-items-center justify-content-center text-primary bg-light rounded-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Bookmark"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                            </svg>
                          </Link>
                        </div>
                        <div className="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mb-2">
                          {/* <!-- start ratings --> */}
                          <i className="fa-solid fa-star"></i>
                          {/* <!-- end /. ratings --> */}
                          {/* <!-- start rating counter text --> */}
                          <span className="fw-medium text-primary">
                            <span className="fs-6 fw-semibold me-1">(4.5)</span>
                            2,391 reviews
                          </span>
                          {/* <!-- end /. rating counter text --> */}
                        </div>
                        {/* <!-- start card title --> */}
                        <h4 className="fs-18 fw-semibold mb-0">
                          Green Mart Apartment
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-patch-check-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                          </svg>
                        </h4>
                        {/* <!-- end /. card title --> */}
                        {/* <!-- start card description --> */}
                        <p className="mt-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor.
                        </p>
                        {/* <!-- end /. card description --> */}
                        {/* <!-- start contact content --> */}
                        <div className="d-flex flex-wrap gap-3 mt-auto z-1">
                          <Link
                            to="tel:+4733378901"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="#9b9b9b"
                              className="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            <span>(123) 456-7890</span>
                          </Link>
                          <Link
                            to="#"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#9b9b9b"
                              className="bi bi-compass"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                              <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                            </svg>
                            <span>Directions</span>
                          </Link>
                        </div>
                        {/* <!-- end contact content --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end /. card list --> */}
            </div>
            <div className="col-lg-6">
              {/* <!-- start card list --> */}
              <div className="card border-0 shadow-sm overflow-hidden rounded-4 card-hover">
                <Link to="#" className="stretched-link"></Link>
                <div className="card-body p-0">
                  <div className="g-0 row">
                    <div className="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                      <div className="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                        {/* <!-- start image --> */}
                        <img
                          src="/assets/images/place/02.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover"
                        />
                        {/* <!-- end /. image --> */}
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          <i className="fa-solid fa-star me-1"></i>Featured
                        </div>
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          $100 off $399: eblwc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-1">
                          <Link
                            to="#"
                            className="btn-icon shadow-sm d-flex align-items-center justify-content-center text-primary bg-light rounded-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Bookmark"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                            </svg>
                          </Link>
                        </div>
                        <div className="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mb-2">
                          {/* <!-- start ratings --> */}
                          <i className="fa-solid fa-star"></i>
                          {/* <!-- end /. ratings --> */}
                          {/* <!-- start rating counter text --> */}
                          <span className="fw-medium text-primary">
                            <span className="fs-6 fw-semibold me-1">(4.5)</span>
                            2,391 reviews
                          </span>
                          {/* <!-- end /. rating counter text --> */}
                        </div>
                        {/* <!-- start card title --> */}
                        <h4 className="fs-18 fw-semibold mb-0">
                          Green Mart Apartment
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-patch-check-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                          </svg>
                        </h4>
                        {/* <!-- end /. card title --> */}
                        {/* <!-- start card description --> */}
                        <p className="mt-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor.
                        </p>
                        {/* <!-- end /. card description --> */}
                        {/* <!-- start contact content --> */}
                        <div className="d-flex flex-wrap gap-3 mt-auto z-1">
                          <Link
                            to="tel:+4733378901"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="#9b9b9b"
                              className="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            <span>(123) 456-7890</span>
                          </Link>
                          <Link
                            to="#"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#9b9b9b"
                              className="bi bi-compass"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                              <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                            </svg>
                            <span>Directions</span>
                          </Link>
                        </div>
                        {/* <!-- end contact content --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end /. card list --> */}
            </div>
            <div className="col-lg-6">
              {/* <!-- start card list --> */}
              <div className="card border-0 shadow-sm overflow-hidden rounded-4 card-hover">
                <Link to="#" className="stretched-link"></Link>
                <div className="card-body p-0">
                  <div className="g-0 row">
                    <div className="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                      <div className="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                        {/* <!-- start image --> */}
                        <img
                          src="/assets/images/place/03.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover"
                        />
                        {/* <!-- end /. image --> */}
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          <i className="fa-solid fa-star me-1"></i>Featured
                        </div>
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          $100 off $399: eblwc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-1">
                          <Link
                            to="#"
                            className="btn-icon shadow-sm d-flex align-items-center justify-content-center text-primary bg-light rounded-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Bookmark"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                            </svg>
                          </Link>
                        </div>
                        <div className="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mb-2">
                          {/* <!-- start ratings --> */}
                          <i className="fa-solid fa-star"></i>
                          {/* <!-- end /. ratings --> */}
                          {/* <!-- start rating counter text --> */}
                          <span className="fw-medium text-primary">
                            <span className="fs-6 fw-semibold me-1">(4.5)</span>
                            2,391 reviews
                          </span>
                          {/* <!-- end /. rating counter text --> */}
                        </div>
                        {/* <!-- start card title --> */}
                        <h4 className="fs-18 fw-semibold mb-0">
                          The Barber's Lounge
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-patch-check-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                          </svg>
                        </h4>
                        {/* <!-- end /. card title --> */}
                        {/* <!-- start card description --> */}
                        <p className="mt-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor.
                        </p>
                        {/* <!-- end /. card description --> */}
                        {/* <!-- start contact content --> */}
                        <div className="d-flex flex-wrap gap-3 mt-auto z-1">
                          <Link
                            to="tel:+4733378901"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="#9b9b9b"
                              className="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            <span>(123) 456-7890</span>
                          </Link>
                          <Link
                            to="#"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#9b9b9b"
                              className="bi bi-compass"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                              <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                            </svg>
                            <span>Directions</span>
                          </Link>
                        </div>
                        {/* <!-- end contact content --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end /. card list --> */}
            </div>
            <div className="col-lg-6">
              {/* <!-- start card list --> */}
              <div className="card border-0 shadow-sm overflow-hidden rounded-4 mb-4 card-hover card-hover-bg">
                <Link to="#" className="stretched-link"></Link>
                <div className="card-body p-0">
                  <div className="g-0 row">
                    <div className="bg-white col-lg-5 col-md-5 col-xl-5 position-relative">
                      <div className="card-image-hover dark-overlay h-100 overflow-hidden position-relative">
                        {/* <!-- start image --> */}
                        <img
                          src="/assets/images/place/04.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover"
                        />
                        {/* <!-- end /. image --> */}
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          <i className="fa-solid fa-star me-1"></i>Featured
                        </div>
                        <div className="bg-primary card-badge d-inline-block fw-semibold position-absolute start-0 text-uppercase text-white z-2">
                          $100 off $399: eblwc
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-xl-7 p-3 p-lg-4 p-md-3 p-sm-4">
                      <div className="d-flex flex-column h-100">
                        <div className="d-flex end-0 gap-2 me-3 mt-3 position-absolute top-0 z-1">
                          <Link
                            to="#"
                            className="btn-icon shadow-sm d-flex align-items-center justify-content-center text-primary bg-light rounded-circle"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Bookmark"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-heart"
                              viewBox="0 0 16 16"
                            >
                              <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                            </svg>
                          </Link>
                        </div>
                        <div className="align-items-center d-flex flex-wrap gap-1 text-primary  card-start mb-2">
                          {/* <!-- start ratings --> */}
                          <i className="fa-solid fa-star"></i>
                          {/* <!-- end /. ratings --> */}
                          {/* <!-- start rating counter text --> */}
                          <span className="fw-medium text-primary">
                            <span className="fs-6 fw-semibold me-1">(4.5)</span>
                            2,391 reviews
                          </span>
                          {/* <!-- end /. rating counter text --> */}
                        </div>
                        {/* <!-- start card title --> */}
                        <h4 className="fs-18 fw-semibold mb-0">
                          Gaming Expo Spectacle
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-patch-check-fill text-success"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                          </svg>
                        </h4>
                        {/* <!-- end /. card title --> */}
                        {/* <!-- start card description --> */}
                        <p className="mt-3">
                          Amet minim mollit non deserunt ullamco est sit aliqua
                          dolor.
                        </p>
                        {/* <!-- end /. card description --> */}
                        {/* <!-- start contact content --> */}
                        <div className="d-flex flex-wrap gap-3 mt-auto z-1">
                          <Link
                            to="tel:+4733378901"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              fill="#9b9b9b"
                              className="bi bi-telephone"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                            <span>(123) 456-7890</span>
                          </Link>
                          <Link
                            to="#"
                            className="d-flex gap-2 align-items-center fs-13 fw-semibold"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#9b9b9b"
                              className="bi bi-compass"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                              <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                            </svg>
                            <span>Directions</span>
                          </Link>
                        </div>
                        {/* <!-- end contact content --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end /. card list --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Places;
