import { HashLoader } from "react-spinners";

const Loader = ({ loading }) => {
    return (
        <>
            {loading && (
                <div className="backdrop">
                    <HashLoader color="#F84525" />
                </div>
            )}
        </>
    )
}

export default Loader;