import React from "react";

const TeamUserCard = (props) => {
  const { img, name, designation } = props;
  return (
    <>
      <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
        {/* <!-- Start Card --> */}
        <div className="team-member">
          <div className="member-header overflow-hidden position-relative rounded-4">
            <div className="d-block overflow-hidden position-relative team-avatar-container">
              <img src={img} className="img-fluid w-100" alt="" />
            </div>
            <ul className="align-items-center d-flex fs-21 gap-2 justify-content-center list-unstyled mb-0 member-social position-absolute">
              <li>
                <a
                  href="#"
                  className="align-items-center d-flex fs-14 justify-content-center rounded-circle text-white"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="align-items-center d-flex fs-14 justify-content-center rounded-circle text-white"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="align-items-center d-flex fs-14 justify-content-center rounded-circle text-white"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
          {/* <!-- Start Avatar Info --> */}
          <div className="member-info mt-3 text-center">
            <h4 className="mb-2 member-name text-truncate">{name}</h4>
            <div>{designation}</div>
          </div>
          {/* <!-- /.End Avatar Info --> */}
        </div>
        {/* <!-- /.End Card --> */}
      </div>
    </>
  );
};

export default TeamUserCard;
