import { Link } from "react-router-dom"

const Blogs = () => {
    return (
        <>
            {/* <!-- start blog section --> */}
            <div className="py-5 position-relative overflow-hidden bg-light">
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-sm-10 col-md-10 col-lg-8 col-xl-7">
                            {/* <!-- start section header --> */}
                            <div className="section-header text-center mb-5" data-aos="fade-down">
                                {/* <!-- start subtitle --> */}
                                <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">Our Latest Articles</div>
                                {/* <!-- end /. subtitle --> */}
                                {/* <!-- start title --> */}
                                <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">Discover Our Latest News And Articles</h2>
                                {/* <!-- end /. title --> */}
                                {/* <!-- start description --> */}
                                <div className="sub-title fs-16">Discover exciting categories. <span className="text-primary fw-semibold">Find what you’re looking for.</span></div>
                                {/* <!-- end /. description --> */}
                            </div>
                            {/* <!-- end /. section header --> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 col-12 mb-lg-0 mb-4">
                            {/* <!-- start article --> */}
                            <article className="card h-100 border-0 shadow-sm">
                                <div className="position-relative">
                                    <Link to="#" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></Link>
                                    <Link to="#" className="align-items-center bg-white btn-icon d-flex end-0 justify-content-center me-3 mt-3 position-absolute rounded-circle text-primary top-0 z-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Read later" data-bs-original-title="Read later">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                        </svg>
                                    </Link>
                                    <div className="card-img-top overflow-hidden position-relative">
                                        <img src="/assets/images/blog/02-lg.jpg" className="card-img-top image-zoom-hover" alt="Image" />
                                    </div>
                                </div>
                                <div className="card-body pb-4">
                                    {/* <!-- start meta info --> */}
                                    <div className="hstack gap-3 mb-3">
                                        <span className="fs-sm small text-muted">9 hours ago</span>
                                        <span className="opacity-25">|</span>
                                        <Link className="badge border fw-semibold text-primary bg-white" to="#">Events</Link>
                                    </div>
                                    {/* <!-- end /. meta info --> */}
                                    <h3 className="fs-4 fw-semibold mb-0 post-title">
                                        <Link to="#">Etiam Dapibus Metus Aliquam Orci Venenatis, Suscipit Efficitur.</Link>
                                    </h3>
                                </div>
                                <div className="card-footer py-4">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src="/assets/images/avatar/01.jpg" className="rounded-circle" width="48" height="48" alt="Avatar" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to="#" className="d-block"><span className="fst-italic text-muted">By</span> <span className="fw-medium">Ethan Blackwood</span></Link>
                                            <small className="text-muted">Engineer</small>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <!-- end /. article --> */}
                        </div>
                        <div className="col">
                            {/* <!-- start article --> */}
                            <article className="card overflow-hidden mb-4 border-0 shadow-sm">
                                <div className="row g-0">
                                    <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover">
                                        <div className="h-100 overflow-hidden position-absolute position-relative start-0 top-0 w-100">
                                            <img src="/assets/images/blog/01-sm.jpg" alt="Image" className="h-100 image-zoom-hover object-fit-cover w-100" />
                                        </div>
                                        <Link to="#" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></Link>
                                        <Link to="#" className="align-items-center bg-white btn-icon d-flex end-0 justify-content-center me-3 mt-3 position-absolute rounded-circle text-primary top-0 z-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Read later" data-bs-original-title="Read later">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark" viewBox="0 0 16 16">
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="card-body">
                                            {/* <!-- start meta info --> */}
                                            <div className="hstack gap-3 mb-3">
                                                <span className="fs-sm small text-muted">9 hours ago</span>
                                                <span className="opacity-25">|</span>
                                                <Link className="badge border fw-semibold text-primary bg-white" to="#">Events</Link>
                                            </div>
                                            {/* <!-- end /. meta info --> */}
                                            <h3 className="h5 fw-semibold post-title">
                                                <Link to="#">Etiam in lorem malesuada, gravida felis in, pretium lacus.</Link>
                                            </h3>
                                            <hr className="my-4" />
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img src="/assets/images/avatar/02.jpg" className="rounded-circle" width="48" height="48" alt="Avatar" />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <Link to="#" className="d-block"><span className="fst-italic text-muted">By</span> <span className="fw-medium">Alexander Kaminski</span></Link>
                                                    <small className="text-muted">Engineer</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <!-- end /. article --> */}
                            {/* <!-- start article --> */}
                            <article className="card overflow-hidden border-0 shadow-sm">
                                <div className="row g-0">
                                    <div className="col-sm-5 position-relative bg-position-center bg-repeat-0 bg-size-cover">
                                        <div className="h-100 overflow-hidden position-absolute position-relative start-0 top-0 w-100">
                                            <img src="/assets/images/blog/02-sm.jpg" alt="Image" className="h-100 image-zoom-hover object-fit-cover w-100" />
                                        </div>
                                        <Link to="#" className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></Link>
                                        <Link to="#" className="align-items-center bg-white btn-icon d-flex end-0 justify-content-center me-3 mt-3 position-absolute rounded-circle text-primary top-0 z-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Read later" data-bs-original-title="Read later">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark" viewBox="0 0 16 16">
                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="card-body">
                                            {/* <!-- start meta info --> */}
                                            <div className="hstack gap-3 mb-3">
                                                <span className="fs-sm small text-muted">Oct 02, 2023</span>
                                                <span className="opacity-25">|</span>
                                                <Link className="badge border fw-semibold text-primary bg-white" to="#">Events</Link>
                                            </div>
                                            {/* <!-- end /. meta info --> */}
                                            <h3 className="h5 fw-semibold post-title">
                                                <Link to="#">Ut ut velit et eros gravida rutrum at sit amet ligula.</Link>
                                            </h3>
                                            <hr className="my-4" />
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <img src="/assets/images/avatar/04.jpg" className="rounded-circle" width="48" height="48" alt="Avatar" />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <Link to="#" className="d-block"><span className="fst-italic text-muted">By</span> <span className="fw-medium">Pranoti Deshpande</span></Link>
                                                    <small className="text-muted">Engineer</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <!-- end /. article --> */}
                        </div>
                        <div className="col-12 mt-4 pt-lg-4 pt-3 text-center">
                            <Link to="#" className="btn btn-lg btn-primary">More blog posts</Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end /. blog section --> */}
        </>
    )
}

export default Blogs;