import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { serviceGetFooterOption } from "../../../services/index.service";
import { HTTP_STATUS_CODES } from "../../../constants/ApiUrl";

const Footer = () => {
  // State for storing the nav categories 
  const [footerData, setFooterData] = useState({
    popular_tags: [],
    top_categories: []
  });

  useEffect(() => {
    getFooteritems();
  }, [])

  const getFooteritems = () => {
    try {
      serviceGetFooterOption().then((res) => {
        if (res.status === HTTP_STATUS_CODES.OK) {
          setFooterData({
            ...footerData,
            popular_tags: res.data.data.popular_tags,
            top_categories: res.data.data.top_categories,
          });
        }
      })
    } catch (error) {
      console.log(error)
      setFooterData([]);
    }
  }


  return (
    <>
      <footer className="footer-dark main-footer overflow-hidden position-relative pt-5">

        <div className="container pt-4">

          <div className="py-5">
            {/* <!-- start app download content --> */}
            <div className="bg-primary rounded-4">
              <div className="col-xxl-10 col-md-11 col-10 d-flex flex-md-row flex-column-reverse align-items-md-end align-items-center mx-auto px-0 gap-4">
                <img
                  className="app-image flex-shrink-0"
                  src="/assets/images/phone-mpckup.png"
                  width="270"
                  alt="Mobile app"
                />
                <div className="align-items-lg-center align-self-center d-flex flex-column flex-lg-row ps-xxl-4 pt-5 py-md-3 text-center text-md-start">
                  <div className="me-md-5">
                    <h4 className="text-white">Download Our App</h4>
                    <p className="mb-lg-0 text-white">
                      It is a long established fact that a reader will be
                      distracted by the readable content.
                    </p>
                  </div>
                  <div className="d-flex flex-shrink-0 flex-wrap gap-3 justify-content-center">
                    {/* <!-- start apple store button --> */}
                    <a
                      className="align-items-center app-btn d-flex px-3 py-2 rounded-3 text-decoration-none text-white border"
                      href="#"
                    >
                      {" "}
                      <i className="fa-apple fab fs-28 me-2"></i>
                      <div>
                        {" "}
                        <span className="fs-13 d-block">
                          Available on the
                        </span>{" "}
                        <span className="fs-17 text-capitalize">App Store</span>{" "}
                      </div>
                    </a>
                    {/* <!-- end /. apple store button --> */}
                    <a
                      className="align-items-center app-btn d-flex fs-11 px-3 py-2 rounded-3 text-decoration-none text-white border"
                      href="#"
                    >
                      {" "}
                      <i className="fab fa-google-play fs-25 me-2"></i>
                      <div>
                        {" "}
                        <span className="fs-13 d-block">Get it on</span>{" "}
                        <span className="fs-17 text-capitalize">
                          Google Play
                        </span>{" "}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end /. app download content --> */}
          </div>

          <div className="border-top py-5 text-center">
            <h5 className="mb-0">
              Free support : <strong>+61 0473-618-444</strong> | Email : contactus@letsfind.com.au
            </h5>
          </div>

          <div className="border-top py-5">
            <div className="footer-row row gy-5 g-sm-5 gx-xxl-6">
              <div className="border-end col-lg-4 col-md-7 col-sm-6">
                <h5 className="fw-bold mb-4">Top Category</h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      {footerData.top_categories
                        .slice(0, Math.ceil(footerData.top_categories.length / 2))
                        .map((el, index) => (
                          <div className="col-12" key={index}>
                            <Link to={el.path}>{el.category_name}</Link>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      {footerData &&
                        footerData.top_categories &&
                        Array.isArray(footerData.top_categories) &&
                        footerData.top_categories.length > 0 &&
                        footerData.top_categories
                          .slice(Math.ceil(footerData.top_categories.length / 2))
                          .map((el, index) => (
                            <div className="col-12" key={index}>
                              <Link to={el.path}>{el.category_name}</Link>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
                {/* <div className="mb-4">
                  Join our newsletter and receive the best job
                  <br className="d-none d-xxl-block" /> openings of the week,
                  right on your inbox.
                </div>
                <div className="border rounded-4 p-4 mb-4">
                  <h6 className="text-white-50 mb-3">Join our Whatsapp:</h6>
                  <a
                    className="align-items-center d-block d-flex whatsapp-number"
                    href="#"
                  >
                    <i className="fa-brands fa-whatsapp fs-2 me-2"></i>
                    <span className="fs-5 fw-semibold text-decoration-underline">
                      +61 0473-618-444
                    </span>
                  </a>
                </div>
                <h5 className="fw-bold mb-4">
                  Want to join ListOn?
                  <br /> Write us !
                </h5>
                <span>contactus@letsfind.com.au</span> */}
              </div>
              <div className="border-end col-lg-4 col-md-5 col-sm-6">
                <h5 className="fw-bold mb-4">Trending Category</h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      {footerData.top_categories
                        .slice(0, Math.ceil(footerData.top_categories.length / 2))
                        .map((el, index) => (
                          <div className="col-12" key={index}>
                            <Link to={el.path}>{el.category_name}</Link>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      {footerData &&
                        footerData.top_categories &&
                        Array.isArray(footerData.top_categories) &&
                        footerData.top_categories.length > 0 &&
                        footerData.top_categories
                          .slice(Math.ceil(footerData.top_categories.length / 2))
                          .map((el, index) => (
                            <div className="col-12" key={index}>
                              <Link to={el.path}>{el.category_name}</Link>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4">
                <h5 className="fw-bold mb-4">HELP & SUPPORT</h5>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <Link to="/about">About us</Link>
                      </div>
                      <div className="col-12">
                        <Link to="#">FAQ</Link>
                      </div>
                      <div className="col-12">
                        <Link to="#">Feedback</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-12">
                        <Link to="/contact-us">Contact us</Link>
                      </div>
                      <div className="col-12">
                        <Link to="#">Privacy Policy</Link>
                      </div>
                      <div className="col-12">
                        <Link to="#">Terms of Use</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top py-5">
            <h5 className="fw-bold mb-4">Popular Tags</h5>
            {footerData.popular_tags && footerData.popular_tags.length > 0 && footerData.popular_tags.map((el, index) => {
              return (
                <div className="d-inline-block me-5" key={index}>
                  <Link to={el.popular_tags_link}>{el.popular_tags_name}</Link>
                </div>
              );
            })}
          </div>

          <div className="border-top py-5">
            <h5 className="fw-bold mb-4">Get In Touch</h5>
            <div className="mb-4">Join our newsletter and receive the best job<br className="d-none d-xxl-block" /> openings of the week, right on your inbox.</div>
            <div className="d-flex flex-wrap">
              <p className="mb-0 me-4">Address: Melbourne, Australia</p>
              <p className="mb-0 me-4">Phone: +61 0473-618-444</p>
              <p className="mb-0">Email: contactus@letsfind.com.au</p>
            </div>
          </div>


        </div>

        <div className="container border-top">
          <div className="align-items-center g-3 py-4 row">
            <div className="col-lg-auto">
              {/* <!-- start footer nav --> */}
              <ul className="list-unstyled list-separator mb-2 footer-nav">
                <li className="list-inline-item">
                  <a href="#">Privacy</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">Sitemap</a>
                </li>
                <li className="list-inline-item">
                  <a href="#">Cookies</a>
                </li>
              </ul>
              {/* <!-- end /. footer nav --> */}
            </div>
            <div className="col-lg order-md-first">
              <div className="align-items-center row">
                {/* <!-- start footer logo --> */}
                <Link
                  to="/"
                  className="col-sm-auto footer-logo mb-2 mb-sm-0"
                >
                  <img src="/assets/images/logo.png" alt="" />
                </Link>
                {/* <!-- end /. footer logo --> */}
                {/* <!-- start text --> */}
                <div className="col-sm-auto copy">
                  © {moment().format('YYYY')} Letsfind - All Rights Reserved- All Rights Reserved
                </div>
                {/* <!-- end /. text --> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;