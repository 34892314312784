import axios from "axios";
import { PATH } from "../constants/ApiUrl";

export const serviceGetNavOption = async () => {
    return await axios.get(`${PATH.NAVBAR}`)
}

export const serviceGetCities = async () => {
    return await axios.get(`${PATH.GET_CITIES}`)
}

export const serviceListingsData = async (filterobject) => {
    return await axios.get(`${PATH.GET_SERVICE_LISTINGS}`, { params: filterobject })
}

export const serviceGetFooterOption = async () => {
    return await axios.get(`${PATH.FOOTER}`)
}

export const serviceForgotPassword = async (email) => {
    return await axios.post(`${PATH.FORGOT_PASSWORD}`, email)
}

export const serviceResetPassword = async (credentials) => {
    return await axios.post(`${PATH.RESET_PASSWORD}`, credentials)
}

export const serviceRegister = async (credentials) => {
    return await axios.post(`${PATH.REGISTER}`, credentials)
}

export const serviceLogin = async (credentials) => {
    return await axios.post(`${PATH.LOGIN}`, credentials)
}

export const serviceOtpVerify = async (data) => {
    return await axios.post(`${PATH.VERIFY_OTP}`, data)
}

export const serviceListingsDetails = async (listingId) => {
    return await axios.get(`${PATH.GET_LISTING_DETAILS}`, { params: { listing_id: listingId } })
}
export const serviceGetReviewList = async (listingId) => {
    return await axios.get(`${PATH.GET_REVIEWS_LISTING}/${listingId}/reviews`)
}
