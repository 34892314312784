import React, { useEffect, useState } from 'react'
import StartListingCarousel from '../Home/startListingCarousel'
import $ from 'jquery';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'magnific-popup';
import SearchBar from '../common/searchBar';
import { useSelector } from 'react-redux';
import Loader from '../common/Loader';
import { serviceListingsDetails, serviceGetReviewList } from '../../services/index.service';
import { HTTP_STATUS_CODES } from '../../constants/ApiUrl';
import { useForm } from 'react-hook-form';
import { VALIDATE_QUOTE_SCHEMA } from '../../schema/register.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import StarRating from '../common/starRating';
import { formatDateTime } from '../../helpers';

const CategoryDetail = () => {
    const [searchParams] = useSearchParams();
    const urlId = searchParams.get('id');
    const { id } = useParams(); // Get the id from the URL


    const reduxData = useSelector((state) => state.allCategories.navbarData);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VALIDATE_QUOTE_SCHEMA),
        mode: "onChange",
    });

    const [filters, setFilters] = useState({
        searchText: "",
        distance: "",
        location: "",
        category: [],
        order: "Latest"
    })
    const [loading, setLoading] = useState(false);
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [isMapContentOpened, setIsMapContentOpened] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [reviewsData, setReviewsData] = useState([]);
    console.log(reviewsData, "reviewsData");

    useEffect(() => {
        if (document.querySelectorAll('.popup-youtube').length) {
            $(".popup-youtube").magnificPopup({
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });
        }
        if (document.querySelectorAll('.zoom-gallery').length) {
            $('.zoom-gallery').magnificPopup({
                delegate: 'a',
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    //   titleSrc: function (item) {
                    //     return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-source') + '" target="_blank">image source</a>';
                    //   }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300,
                    opener: function (element) {
                        return element.find('img');
                    }
                }
            });
        }
        if (document.querySelectorAll('.zoom-gallery-two').length) {
            $('.zoom-gallery-two').magnificPopup({
                delegate: 'a',
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: false,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                image: {
                    verticalFit: true,
                    //   titleSrc: function (item) {
                    //     return item.el.attr('title') + ' &middot; <a class="image-source-link" href="' + item.el.attr('data-source') + '" target="_blank">image source</a>';
                    //   }
                },
                gallery: {
                    enabled: true
                },
                zoom: {
                    enabled: true,
                    duration: 300,
                    opener: function (element) {
                        return element.find('img');
                    }
                }
            });
        }
        if (urlId) {
            try {
                setLoading(true);
                // Fetch listing details
                serviceListingsDetails(urlId)
                    .then((response) => {
                        if (response.status === HTTP_STATUS_CODES.OK) {
                            setCategoryData(response.data.data);
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                    });

                // Fetch reviews
                serviceGetReviewList(urlId)
                    .then((response) => {
                        if (response.status === HTTP_STATUS_CODES.OK) {
                            setReviewsData(response.data.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err, "error fetching reviews");
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        } else {
            window.history.back();
        }

    }, [urlId]);

    const handleChangeFilters = (e) => {
        const { value, name, checked } = e.target;

        if (name === "category") {
            // Update the category array
            setFilters({
                ...filters,
                category: checked
                    ? [...filters.category, value]
                    : filters.category.filter(category => category !== value)
            });
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    }

    const handleSidebarCollapseClick = () => {
        setIsSidebarActive(!isSidebarActive);
        setIsMapContentOpened(!isMapContentOpened);
    };

    const navigate = useNavigate();
    const onSubmit = (userData) => {
        const isTokenAvailable = localStorage.getItem("token");
        if (isTokenAvailable) {
            alert("Successfully sent")
        }
        else {
            // alert("Please Login first")
            navigate('/login')
        }
    };

    const RelativeTime = (timestamp) => {

        const formatRelativeTime = (timestamp) => {
            return !timestamp ? moment().fromNow() : moment(timestamp).fromNow();
        };

        return <span>{formatRelativeTime(timestamp)}</span>;
    };


    return (
        <>
            <Loader loading={loading} />
            {/* <!-- start details header --> */}

            <div className="py-4">
                <div className="container">

                    {/* <!-- end /. header search bar  --> */}

                    <div className="justify-content-between row align-items-center g-4">
                        <div className="col-lg col-xxl-8">
                            {/* Breadcrumbs */}
                            <ul className="fs-14 fw-bolder list-inline text-muted mb-3 mt-2">
                                <li className="list-inline-item">
                                    <Link to="/all-category">
                                        All Services
                                    </Link>
                                </li>
                                <li className="list-inline-item">
                                    <span> &gt; </span> {/* Changed "/" to ">" */}
                                </li>
                                <li className="list-inline-item">
                                    <Link to="#">
                                        {categoryData?.listing_name ?? ""}
                                    </Link>
                                </li>
                            </ul>
                            <h1 className="h2 page-header-title fw-semibold">{categoryData?.listing_name ?? ""}</h1>
                            <ul className="list-inline list-separator d-flex align-items-center ">
                                <li className="list-inline-item">
                                    <a className="fw-medium" target='_blank' href={categoryData?.listing_website ?? "#"}>{categoryData?.listing_name ? categoryData?.listing_name?.split(" ")?.at(0) : "Website"}<i className="fa-solid fa-arrow-up-right-from-square fs-14 ms-2"></i></a>
                                </li>
                                <li className="list-inline-item">
                                    <StarRating
                                        averageRating={categoryData?.average_rating || 0}
                                        reviewCount={categoryData?.review_count || 0}
                                    />
                                </li>
                            </ul>


                            <ul className="fs-14 fw-medium list-inline list-separator mb-0 text-muted">
                                <li className="list-inline-item">Posted {RelativeTime(categoryData.listing_cdt)}</li>
                                {categoryData?.listing_address && <li className="list-inline-item">
                                    <span className="me-2">
                                        <svg viewBox="0 0 1024 1024" height="20px" width="20px" fill="#7d7d7d" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#7d7d7d"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>
                                    </span>
                                    {categoryData?.listing_address ?? ""}
                                </li>}
                                {categoryData?.listing_mobile && <li className="list-inline-item">
                                    <span className='me-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" className="bi bi-telephone" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>
                                    </span>
                                    {categoryData?.listing_mobile}
                                </li>}
                                {categoryData?.listing_email && <li className="list-inline-item">
                                    <span className='me-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#9b9b9b" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg>
                                    </span>
                                    {categoryData?.listing_email}
                                </li>}
                            </ul>


                        </div>
                        <div className="col-lg-auto">
                            {/* <!-- start checkbbox bookmark --> */}
                            <div className="form-check form-check-bookmark mb-2 mb-sm-0">
                                <input className="form-check-input" type="checkbox" value="" id="jobBookmarkCheck" />
                                <label className="form-check-label" htmlFor="jobBookmarkCheck">
                                    <span className="form-check-bookmark-default">
                                        <i className="fa-regular fa-heart me-1"></i> Save this listing
                                    </span>
                                    <span className="form-check-bookmark-active">
                                        <i className="fa-solid fa-heart me-1"></i> Saved
                                    </span>
                                </label>
                            </div>
                            {/* <!-- end /. checkbbox bookmark --> */}
                            {/* <div className="small mt-1">46 people bookmarked this place</div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end /. details header --> */}

            {/* <!-- start gallery --> */}
            <div className="container">
                <div className="rounded-4 overflow-hidden">
                    <div className="row gx-2 zoom-gallery">
                        <div className="col-md-8">
                            <a className="d-block position-relative" href="assets/images/listing-details/gallery/08.jpg">
                                <img className="img-fluid w-100" src="assets/images/listing-details/gallery/08.jpg" alt="Image Description" />
                                <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                                    <span className="align-items-center btn btn-light btn-sm d-flex d-md-none fw-semibold gap-2">
                                        <i className="fa-solid fa-expand"></i>
                                        <span> View photos</span>
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-4 d-none d-md-inline-block">
                            <a className="d-block mb-2" href="assets/images/listing-details/gallery/09.jpg">
                                <img className="img-fluid w-100" src="assets/images/listing-details/gallery/09.jpg" alt="Image Description" />
                            </a>
                            <a className="d-block position-relative" href="assets/images/listing-details/gallery/10.jpg">
                                <img className="img-fluid w-100" src="assets/images/listing-details/gallery/10.jpg" alt="Image Description" />
                                <div className="position-absolute bottom-0 end-0 mb-3 me-3">
                                    <span className="align-items-center btn btn-light btn-sm d-md-inline-flex d-none fw-semibold gap-2">
                                        <i className="fa-solid fa-expand"></i>
                                        <span> View photos</span>
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                    <span className="small text-dark fw-semibold">Published:</span>
                    <span className="small ms-1 text-muted">November 21, 2023</span>
                </div>
            </div>
            {/* <!-- end /. gallery --> */}

            {/* <!-- start listing details --> */}
            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 content">
                            <div className="mb-4">
                                <h4 className="fw-semibold fs-3 mb-4">Enterprise <span className="font-caveat text-primary">Description</span></h4>
                                <p dangerouslySetInnerHTML={{ __html: categoryData?.listing_description ?? "" }} />

                            </div>
                            <div className="mb-4">
                                <h4 className="fw-semibold fs-3 mb-4">Services <span className="font-caveat text-primary">Available</span></h4>
                                <div className="row g-4">

                                    <div className="col-sm-6 col-lg-4 col-xl-12 col-xxl-6 d-flex">
                                        <div className="card rounded-3 border-0 shadow-sm w-100 flex-fill overflow-hidden card-hover flex-fill w-100 card-hover-bg me-2">
                                            {/* <!-- start card image wrap --> */}
                                            <div className="card-img-wrap card-image-hover overflow-hidden dark-overlay z-0">
                                                <img src={`${categoryData?.service_1_image}`} alt="" className="img-fluid" />
                                            </div>
                                            {/* <!-- end /. card image wrap --> */}
                                            <div className="d-flex flex-column h-100 position-relative p-3">

                                                {/* <!-- start card title --> */}
                                                <h4 className="fs-18 fw-semibold mb-0">
                                                    {categoryData?.service_1_name}
                                                </h4>
                                                {/* <!-- end /. card title --> */}
                                            </div>
                                        </div>

                                        <div className="card rounded-3 border-0 shadow-sm w-100 flex-fill overflow-hidden card-hover flex-fill w-100 card-hover-bg me-2 ">
                                            {/* <!-- start card image wrap --> */}
                                            <div className="card-img-wrap card-image-hover overflow-hidden dark-overlay z-0">
                                                <img src={`${categoryData?.service_2_image}`} alt="" className="img-fluid" />
                                            </div>
                                            {/* <!-- end /. card image wrap --> */}
                                            <div className="d-flex flex-column h-100 position-relative p-3">

                                                {/* <!-- start card title --> */}
                                                <h4 className="fs-18 fw-semibold mb-0">
                                                    {categoryData?.service_2_name}
                                                </h4>
                                                {/* <!-- end /. card title --> */}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <hr className="my-5" />
                            {/* <!-- start pricing  section --> */}
                            <div className="mb-4">
                                <h4 className="fw-semibold fs-3 mb-4">Pricing</h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Wild Mushroom Bucatini with Kale</h4>
                                                    <div className="fs-14 menu-detail text-muted">Mushroom / Veggie / White Sources</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$10.5</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Lemon and Garlic Green Beans</h4>
                                                    <div className="fs-14 menu-detail text-muted">Lemon / Garlic / Beans</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$14.5</h4>
                                                    <div className="d-inline-block font-caveat fs-13 fw-bold menu-label text-primary">New</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">LambBeef Kofka Skewers with Tzatziki</h4>
                                                    <div className="fs-14 menu-detail text-muted">Lamb / Wine / Butter</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$18.5</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Imported Oysters Grill (5 Pieces)</h4>
                                                    <div className="fs-14 menu-detail text-muted">Oysters / Veggie / Ginger</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$15.9</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Meatloaf with Black Pepper-Honey BBQ</h4>
                                                    <div className="fs-14 menu-detail text-muted">Pepper / Chicken / Honey</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$16.4</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Wild Mushroom Bucatini with Kale</h4>
                                                    <div className="fs-14 menu-detail text-muted">Mushroom / Veggie / White Sources</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$14.5</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Lemon and Garlic Green Beans</h4>
                                                    <div className="fs-14 menu-detail text-muted">Lemon / Garlic / Beans</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$14.5</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">LambBeef Kofka Skewers with Tzatziki</h4>
                                                    <div className="fs-14 menu-detail text-muted">Lamb / Wine / Butter</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$18.5</h4>
                                                    <div className="d-inline-block font-caveat fs-13 fw-bold menu-label text-primary">Recommended</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Imported Oysters Grill (5 Pieces)</h4>
                                                    <div className="fs-14 menu-detail text-muted">Oysters / Veggie / Ginger</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$15.9</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 menu pb-2">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <h4 className="fs-17 mb-0 menu-title">Meatloaf with Black Pepper-Honey BBQ</h4>
                                                    <div className="fs-14 menu-detail text-muted">Pepper / Chicken / Honey</div>
                                                </div>
                                                <div className="col-sm-4 menu-price-detail text-end">
                                                    <h4 className="fs-17 mb-0 menu-price">$16.4</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end /. pricing section --> */}
                            <hr className="my-5" />
                            {/* <!-- start reviews section --> */}
                            <div className="mb-4">
                                <h4 className="fw-semibold fs-3 mb-4">Latest Property <span className="font-caveat text-primary">Reviews</span></h4>
                                <div className="border p-4 mb-5 rounded-4">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-sm-auto">
                                            <div className="rating-block text-center">
                                                {/* <!-- start title -->  */}
                                                <h5 className="font-caveat fs-4 mb-4">Average user rating</h5>
                                                {/* <!-- end /. title -->  */}
                                                {/* <!-- Start Rating Point -->  */}
                                                <div className="rating-point position-relative ml-auto mr-auto">
                                                    {/* <!-- Start Svg Icon  -->  */}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth=".5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star text-primary">
                                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"> </polygon>
                                                    </svg>
                                                    {/* <!-- /.End Svg Icon  -->  */}
                                                    <h3 className="position-absolute mb-0 fs-18 text-primary">4.3</h3>
                                                </div>
                                                {/* <!-- End Rating Point -->  */}
                                                <span className="fs-13">2,525 Ratings &amp;</span><br />
                                                <span className="fs-13">293 Reviews</span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="rating-position">
                                                {/* <!-- start title -->  */}
                                                <h5 className="font-caveat fs-4 mb-4">Rating breakdown</h5>
                                                {/* <!-- end /. title -->  */}
                                                {/* <!-- Start Rating Point -->  */}
                                                {/* <!-- start rating dimension -->  */}
                                                <div className="align-items-center d-flex mb-2 rating-dimension gap-2">
                                                    {/* <!-- start rating quantity -->  */}
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="fs-14 fw-semibold rating-points">5</span>
                                                        <div className="d-flex align-items-center text-primary rating-stars">
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end /. rating quantity -->  */}
                                                    {/* <!-- Start Progress -->  */}
                                                    <div className="progress flex-grow-1 me-2">
                                                        <div className="progress-bar bg-primary" role="progressbar" style={{ "width": "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    {/* <!-- /.End Progress -->  */}
                                                    {/* <!-- Start User Rating -->  */}
                                                    <div className="bg-dark fs-11 fw-medium px-2 py-1 rounded-pill text-white user-rating">4.5</div>
                                                    {/* <!-- /.End User Rating -->  */}
                                                </div>
                                                {/* <!-- end /. rating dimension -->  */}
                                                {/* <!-- start rating dimension -->  */}
                                                <div className="align-items-center d-flex mb-2 rating-dimension gap-2">
                                                    {/* <!-- start rating quantity -->  */}
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="fs-14 fw-semibold rating-points">5</span>
                                                        <div className="d-flex align-items-center text-primary rating-stars">
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon half"></i>
                                                            <i className="fa-star-icon none"></i>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end /. rating quantity -->  */}
                                                    {/* <!-- start progress -->  */}
                                                    <div className="progress flex-grow-1 me-2">
                                                        <div className="progress-bar bg-success" role="progressbar" style={{ "width": "80%" }} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    {/* <!-- end /. progress -->  */}
                                                    {/* <!-- start user rating -->  */}
                                                    <div className="bg-dark fs-11 fw-medium px-2 py-1 rounded-pill text-white user-rating">3.5</div>
                                                    {/* <!-- end /. user rating -->  */}
                                                </div>
                                                {/* <!-- end /. rating dimension -->  */}
                                                {/* <!-- start rating dimension -->  */}
                                                <div className="align-items-center d-flex mb-2 rating-dimension gap-2">
                                                    {/* <!-- start rating quantity -->  */}
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="fs-14 fw-semibold rating-points">3</span>
                                                        <div className="d-flex align-items-center text-primary rating-stars">
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon half"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end /. rating quantity -->  */}
                                                    {/* <!-- start progress -->  */}
                                                    <div className="progress flex-grow-1 me-2">
                                                        <div className="progress-bar bg-warning" role="progressbar" style={{ "width": "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    {/* <!-- end /. progress -->  */}
                                                    {/* <!-- start user rating -->  */}
                                                    <div className="bg-dark fs-11 fw-medium px-2 py-1 rounded-pill text-white user-rating">1.5</div>
                                                    {/* <!-- end /. user rating -->  */}
                                                </div>
                                                {/* <!-- end /. rating dimension -->  */}
                                                {/* <!-- start rating dimension -->  */}
                                                <div className="align-items-center d-flex mb-2 rating-dimension gap-2">
                                                    {/* <!-- start rating quantity -->  */}
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="fs-14 fw-semibold rating-points">3</span>
                                                        <div className="d-flex align-items-center text-primary rating-stars">
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon half"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end /. rating quantity -->  */}
                                                    {/* <!-- start progress -->  */}
                                                    <div className="progress flex-grow-1 me-2">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{ "width": "40%" }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    {/* <!-- end /. progress -->  */}
                                                    {/* <!-- start user rating -->  */}
                                                    <div className="bg-dark fs-11 fw-medium px-2 py-1 rounded-pill text-white user-rating">5.2</div>
                                                    {/* <!-- end /. user rating -->  */}
                                                </div>
                                                {/* <!-- end /. rating dimension -->  */}
                                                {/* <!-- start rating dimension -->  */}
                                                <div className="align-items-center d-flex mb-2 rating-dimension gap-2">
                                                    {/* <!-- start rating quantity -->  */}
                                                    <div className="d-flex align-items-center gap-2">
                                                        <span className="fs-14 fw-semibold rating-points">1</span>
                                                        <div className="d-flex align-items-center text-primary rating-stars">
                                                            <i className="fa-star-icon"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                            <i className="fa-star-icon none"></i>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end /. rating quantity -->  */}
                                                    {/* <!-- start progress -->  */}
                                                    <div className="progress flex-grow-1 me-2">
                                                        <div className="progress-bar text-bg-danger" role="progressbar" style={{ "width": "20%" }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    {/* <!-- end /. progress -->  */}
                                                    {/* <!-- start user rating -->  */}
                                                    <div className="bg-dark fs-11 fw-medium px-2 py-1 rounded-pill text-white user-rating">6.9</div>
                                                    {/* <!-- end /. user rating -->  */}
                                                </div>
                                                {/* <!-- end /. rating dimension -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mb-4 border-bottom pb-4">

                                </div>
                                {reviewsData?.reviews?.length > 0 && reviewsData.reviews?.map((review, index) => (
                                    <div key={index} className="d-flex mb-4">
                                        <div className="flex-shrink-0">
                                            <img src="assets/images/avatar/05.jpg" alt="..." height="70" width="70" className="object-fit-cover rounded-circle" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <div className="comment-header d-flex flex-wrap gap-2 mb-3">
                                                <div>
                                                    <h4 className="fs-18 mb-0">- {review?.review_name}</h4>
                                                    <div className="comment-datetime fs-12 text-muted">{formatDateTime(review?.review_cdt)}</div>
                                                </div>
                                                {/* <!-- start rating --> */}
                                                <StarRating averageRating={review?.customer_service_rating || 0} />
                                                {/* <!-- end /. rating --> */}
                                            </div>
                                            <div className="fs-15">{review?.review_message || '-'}</div>
                                            {/* <!-- start review --> */}
                                            <a href="#" className="btn btn-light btn-sm mt-4 px-3 rounded-pill gap-2 d-inline-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                                                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                                </svg>
                                                Helpful Review
                                                <div className="vr d-none d-sm-inline-block"></div>
                                                <span className="fw-semibold">{review?.likes_count || 0}</span>
                                            </a>
                                            {/* Comment Gallery  */}
                                            <div className="row mt-3 g-2 review-image zoom-gallery">
                                                <div className="col-auto">
                                                    <a href="assets/images/listing-details/review-image-02.jpg" className="galary-overlay-hover dark-overlay position-relative d-block overflow-hidden rounded-3">
                                                        <img src="assets/images/listing-details/review-image-02.jpg" alt="" className="img-fluid rounded-3 object-fit-cover" height="70" width="112" />
                                                        <div className="galary-hover-element h-100 position-absolute start-50 top-50 translate-middle w-100">
                                                            <i className="fa-solid fa-expand text-white position-absolute top-50 start-50 translate-middle bg-primary rounded-1 p-2 lh-1"></i>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-auto">
                                                    <a href="assets/images/listing-details/review-image-03.jpg" className="galary-overlay-hover dark-overlay position-relative d-block overflow-hidden rounded-3">
                                                        <img src="assets/images/listing-details/review-image-03.jpg" alt="" className="img-fluid rounded-3 object-fit-cover" height="70" width="112" />
                                                        <div className="galary-hover-element h-100 position-absolute start-50 top-50 translate-middle w-100">
                                                            <i className="fa-solid fa-expand text-white position-absolute top-50 start-50 translate-middle bg-primary rounded-1 p-2 lh-1"></i>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-auto">
                                                    <a href="assets/images/listing-details/review-image-04.jpg" className="galary-overlay-hover dark-overlay position-relative d-block overflow-hidden rounded-3">
                                                        <img src="assets/images/listing-details/review-image-04.jpg" alt="" className="img-fluid rounded-3 object-fit-cover" height="70" width="112" />
                                                        <div className="galary-hover-element h-100 position-absolute start-50 top-50 translate-middle w-100">
                                                            <i className="fa-solid fa-expand text-white position-absolute top-50 start-50 translate-middle bg-primary rounded-1 p-2 lh-1"></i>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            {/* Comment section */}
                                            {review?.comments?.length > 0 && review?.comments?.map((comment, index) => <React.Fragment key={index}>
                                                <div className="d-flex mt-4 border-top pt-4">
                                                    <div className="flex-shrink-0">
                                                        <img src="assets/images/avatar/06.jpg" alt="..." height="60" width="60" className="object-fit-cover rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <div className="comment-header d-flex flex-wrap gap-2 mb-3">
                                                            <div>
                                                                <h4 className="fs-18 mb-0">- {comment?.user?.username || '-'}</h4>
                                                                <div className="comment-datetime fs-12 text-muted">{formatDateTime(comment?.created_at || '-')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="fs-15">{comment?.content || '-'}</div>
                                                        {/* <!-- start review --> */}
                                                        <a href="#" className="btn btn-light btn-sm mt-4 px-3 rounded-pill gap-2 d-inline-flex">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                                                                <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                                                            </svg>
                                                            Helpful Review
                                                            <div className="vr d-none d-sm-inline-block"></div>
                                                            <span className="fw-semibold">{comment?.likes_count || 0}</span>
                                                        </a>
                                                        {/* <!-- end /. review -->  */}
                                                    </div>
                                                </div>
                                            </React.Fragment>)}
                                            {/* <!-- end /. review --> */}
                                        </div>
                                    </div>
                                ))

                                }
                            </div>
                            {/* <!-- end /. reviews section --> */}
                            <hr className="my-5" />
                            {/* <!-- start comment form section --> */}
                            <div className="mb-4 mb-lg-0">
                                <h4 className="fw-semibold fs-3 mb-4">Leave a <span className="font-caveat text-primary">Comment</span></h4>
                                <form className="row g-4">
                                    <div className="col-sm-6">
                                        {/* <!-- start form group --> */}
                                        <div className="">
                                            <label className="required fw-medium mb-2">Full Name</label>
                                            <input type="text" className="form-control" placeholder="Enter your name" required="" />
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>
                                    <div className="col-sm-6">
                                        {/* <!-- start form group --> */}
                                        <div className="">
                                            <label className="required fw-medium mb-2">Email Address</label>
                                            <input type="text" className="form-control" placeholder="Enter your email address" />
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>
                                    <div className="col-sm-12">
                                        {/* <!-- start form group --> */}
                                        <div className="">
                                            <label className="required fw-medium mb-2">Comment</label>
                                            <textarea className="form-control" rows="7" placeholder="Tell us what we can help you with!"></textarea>
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>
                                    <div className="col-sm-12 text-end">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                            {/* <!-- end /. comment form section --> */}
                            {/* <hr className="my-5" /> */}

                        </div>

                        <div className="col-lg-4 ps-xxl-5 sidebar">
                            <div className="border mb-4 p-4 rounded-4">
                                <h4 className="fw-semibold mb-4">Get <span className="font-caveat text-primary">Quote</span></h4>
                                <form className="row g-4 register-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="col-sm-12">
                                        {/* <!-- start form group --> */}
                                        <div className="form-group">
                                            <label className="required fw-medium mb-2">Full Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                                placeholder="Enter your name"
                                                {...register("full_name", { required: "Full name is required" })}
                                            />
                                            {errors?.full_name && (
                                                <div className="invalid-feedback text-start">
                                                    {errors.full_name.message}
                                                </div>
                                            )}
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>
                                    <div className="col-sm-12">
                                        {/* <!-- start form group --> */}
                                        <div className="form-group">
                                            <label className="required fw-medium mb-2">Email Address</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                placeholder="Enter your email address"
                                                {...register("email", {
                                                    required: "Email is required",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                        message: "Enter a valid email"
                                                    }
                                                })}
                                            />
                                            {errors?.email && (
                                                <div className="invalid-feedback text-start">
                                                    {errors.email.message}
                                                </div>
                                            )}
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>

                                    <div className="col-sm-12">
                                        {/* <!-- start form group --> */}
                                        <div className="form-group">
                                            <label className="required fw-medium mb-2">Mobile</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.mobile_number ? 'is-invalid' : ''}`}
                                                placeholder="Enter your mobile number"
                                                {...register("mobile_number", {
                                                    required: "Mobile number is required",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Enter a valid 10-digit mobile number"
                                                    }
                                                })}
                                            />
                                            {errors?.mobile_number && (
                                                <div className="invalid-feedback text-start">
                                                    {errors.mobile_number.message}
                                                </div>
                                            )}
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>

                                    <div className="col-sm-12">
                                        {/* <!-- start form group --> */}
                                        <div className="form-group">
                                            <label className="fw-medium mb-2">Comment</label>
                                            <textarea
                                                className={`form-control ${errors.comment ? 'is-invalid' : ''}`}
                                                rows="7"
                                                placeholder="Enter your query or message"
                                                {...register("comment", {
                                                    maxLength: {
                                                        value: 500,
                                                        message: "Comment cannot exceed 500 characters"
                                                    }
                                                })}>
                                            </textarea>
                                            {errors?.comment && (
                                                <div className="invalid-feedback text-start">
                                                    {errors.comment.message}
                                                </div>
                                            )}
                                        </div>
                                        {/* <!-- end /. form group --> */}
                                    </div>

                                    <div className="col-sm-12">
                                        <button type="submit" className="btn btn-primary w-100">Login and make enquiry</button>
                                    </div>
                                </form>

                            </div>

                            {/* <!-- start opening hours --> */}
                            <div className="border p-4 rounded-4">
                                <div className="align-items-center d-flex justify-content-between mb-4">
                                    <h4 className="w-semibold mb-0">Opening <span className="font-caveat text-primary">Hours</span></h4>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
                                    </svg>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Monday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Tuesday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Wednesday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Thursday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Friday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Saturday</span>
                                    <span className="fs-14">8:00 am - 6:00 pm</span>
                                </div>
                                <div className="align-items-center d-flex justify-content-between mb-3">
                                    <span className="fw-semibold">Sunday</span>
                                    <span className="fw-medium text-danger">Close</span>
                                </div>
                            </div>
                            {/* <!-- end /. opening hours --> */}
                        </div>
                    </div>
                </div>
            </div >
            {/* <!-- end /. listing details --> */}
            {/* <StartListingCarousel /> */}

            {/* <!-- start related service section --> */}
            {categoryData?.related_listing?.length && <div className="mb-4">
                <hr className="my-5" />
                <h4 className="fw-semibold fs-3 mb-2 text-center">
                    Related <span className="font-caveat text-primary">Services</span>
                </h4>
                <StartListingCarousel cardsData={categoryData.related_listing} />
                <hr className="my-5" />
            </div>}
            {/* <!-- end related service section --> */}
        </>
    )
}

export default CategoryDetail;