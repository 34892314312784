import React, { useEffect, useState } from "react";
import "../../../Navbar.css";
import { Link } from "react-router-dom";
import { serviceGetNavOption } from "../../../services/index.service";
import { HTTP_STATUS_CODES } from "../../../constants/ApiUrl";
import { useDispatch, useSelector } from "react-redux";
import { storeValue } from "../../../store/slice/slices";

const Navbar = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [navCategories, setNavCategories] = useState([]);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });

  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.allCategories.navbarData);
  const menuItems = reduxData?.menu_items || []

  useEffect(() => {
    // Apply the theme when the component mounts and when theme changes
    document.documentElement.setAttribute('data-bs-theme', theme);
    localStorage.setItem('theme', theme);
    localStorage.setItem('mapStyle', theme);
    localStorage.setItem('imagePath', theme === 'dark' ? 'assets/images/lines-2.svg' : 'assets/images/lines.svg');
  }, [theme]);

  useEffect(() => {
    getNavitems();
  }, [])

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
  };

  const getNavitems = () => {
    try {
      serviceGetNavOption().then((res) => {
        if (res.status === HTTP_STATUS_CODES.OK) {
          dispatch(storeValue(res.data.data))
          setNavCategories(res.data.data.categories);

        }
      })
    } catch (error) {
      console.log(error)
      setNavCategories([]);
    }
  }

  // Function to filter the searched data from navCategories
  const filteredCategories = navCategories?.filter((category) =>
    category?.category_name?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-top">
      <div className="container position-relative">
        <Link className="navbar-brand m-0" to="/">
          <img
            alt="error-img" className="logo-white" src="/assets/images/logo.png"
          />
          <img
            alt="error-img" className="logo-dark" src="/assets/images/logo.png" />
        </Link>
        <div className="d-flex order-lg-2">
          {/* <!-- start button --> */}
          <Link
            to="/login"
            className="d-flex align-items-center justify-content-center p-0 btn-user"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-custom-class="custom-tooltip"
            data-bs-title="Sign In"
          >
            <i className="fa-solid fa-user-plus"></i>
          </Link>
          {/* <!-- end /. button --> */}
          {/* <!-- start button --> */}
          <Link
            to="/login"
            className="d-flex align-items-center justify-content-center p-0 btn-user position-relative"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-custom-class="custom-tooltip"
            data-bs-title="Favourite"
          >
            <i className="fa-solid fa-heart"></i>
            <span className="align-items-center bg-primary d-flex end-0 fs-11 justify-content-center nav-count position-absolute rounded-circle text-white">
              0
            </span>
          </Link>
          {/* <!-- end /. button --> */}
          {/* <!-- start button --> */}
          <button
            type="button"
            id="themeToggleBtn"
            onClick={toggleTheme}
            className="align-items-center bg-transparent border-0 btn-user d-flex justify-content-center p-0"
          >
            <i className="fa-solid fa-moon"></i>
          </button>
          {/* <!-- end /. button --> */}
          {/* <!-- start button --> */}
          <Link
            to="add-listing.html"
            className="btn btn-primary d-none d-sm-flex fw-medium gap-2 hstack rounded-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            <div className="vr d-none d-sm-inline-block"></div>
            <span className="d-none d-sm-inline-block">Add Listing</span>
          </Link>
          {/* <!-- end /. button --> */}
          {/* <!-- start navbar toggle button --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span id="nav-icon" className="">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
          {/* <!-- end /. navbar toggle button --> */}
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 nav-megamenu-links">

            <li className="nav-item">
              <Link to="#" className="nav-link dropdown-toggle desktop-item">
                Explore
              </Link>
              <input type="checkbox" id="showMega" />
              <label
                htmlFor="showMega"
                className="nav-link dropdown-toggle mobile-item"
              >
                Explore
              </label>
              <div className="mega-box">
                <div className="content-menu">
                  <div className="menu-wrapper- pop-menu ani">
                    <div className="row">
                      <div className="pmenu-spri">
                        <ul>
                          {
                            menuItems && menuItems.map((el, index) => {
                              return (
                                <li key={index}>
                                  <Link to={el.url} className="act">
                                    <img
                                      alt="error-img"
                                      src={`${el.imagePath}`}
                                      loading="lazy"
                                    />
                                    {el.name}
                                  </Link>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="pmenu-cat">
                        <h4>All Categories</h4>
                        <input
                          type="text"
                          id="pg-sear"
                          placeholder="Search category"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                        />

                        {/* Render filtered categories */}
                        <ul id="pg-resu">
                          {filteredCategories && filteredCategories.length ? (
                            filteredCategories.map((category, index) => (
                              <li key={index}>
                                <Link to={`/all-category?id=${category.category_id}`}>
                                  {category.category_name} -<span>{category.listing_count}</span>
                                </Link>
                              </li>
                            ))
                          ) : (
                            <li>No categories found</li>
                          )}
                        </ul>
                      </div>
                      <div className="dir-home-nav-bot">
                        <ul>
                          <li>
                            A few reasons you’ll love letsfind Directory{" "}
                            <span>Contact us: +61 0473-618-444</span>
                          </li>
                          <li>
                            <Link
                              to="https://letsfind.com.au/post-your-ads"
                              className="waves-effect waves-light btn-large"
                            >
                              <i className="fa-solid fa-a"></i> Advertise with us{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://letsfind.com.au/pricing-details"
                              className="waves-effect waves-light btn-large"
                            >
                              {" "}
                              <i className="fa-solid fa-store"></i> Add your
                              business{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-sm-none">
            {/* <!-- start button --> */}
            <Link
              to="signin.html"
              className="btn btn-primary d-flex gap-2 hstack justify-content-center rounded-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
              <div className="vr d-none d-sm-inline-block"></div>
              <span>Add Listing</span>
            </Link>
            {/* <!-- end /. button --> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
