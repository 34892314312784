import React from 'react';

const AllServicesPagination = ({ listingData, setFilters, filters }) => {
    const listingsPerPage = 10;
    const totalPages = listingData?.total_count ? Math.ceil(listingData.total_count / listingsPerPage) : 1;

    const handlePageChange = (page) => {
        setFilters({
            ...filters,
            page,
        });
    };

    const renderPageNumbers = () => {
        if (!listingData?.total_count) {
            return (
                <span className="page-numbers current">
                    1
                </span>
            );
        }

        const pageNumbers = [];
        const maxDisplayPages = 2;

        // First few pages
        for (let i = 1; i <= maxDisplayPages; i++) {
            if (i === filters.page) {
                pageNumbers.push(
                    <span key={i} className="page-numbers current">
                        {i}
                    </span>
                );
            } else {
                pageNumbers.push(
                    <button
                        key={i}
                        className="page-numbers"
                        onClick={() => handlePageChange(i)}
                        style={{ background: 'unset', border: 'none', fontWeight: '600' }}
                    >
                        {i}
                    </button>
                );
            }
        }

        // Ellipsis before the current range if needed
        if (filters.page > maxDisplayPages + 2) {
            pageNumbers.push(<small key="start-ellipsis" style={{ padding: '0 5px' }}>...</small>);
        }

        // Current page in the middle, displayed as <span> instead of <button>
        if (filters.page > maxDisplayPages && filters.page <= totalPages - maxDisplayPages) {
            pageNumbers.push(
                <span key={filters.page} className="page-numbers current">
                    {filters.page}
                </span>
            );
        }

        // Ellipsis after the current range if needed
        if (filters.page < totalPages - maxDisplayPages - 1) {
            pageNumbers.push(<small key="end-ellipsis" style={{ padding: '0 5px' }}>...</small>);
        }

        // Last few pages
        for (let i = totalPages - maxDisplayPages + 1; i <= totalPages; i++) {
            if (i === filters.page) {
                pageNumbers.push(
                    <span key={i} className="page-numbers current">
                        {i}
                    </span>
                );
            } else {
                pageNumbers.push(
                    <button
                        key={i}
                        className="page-numbers"
                        onClick={() => handlePageChange(i)}
                        style={{ background: 'unset', border: 'none', fontWeight: '600' }}
                    >
                        {i}
                    </button>
                );
            }
        }
        return pageNumbers;
    };


    return (
        <nav className="justify-content-center mt-auto pagination align-items-center">
            <button
                className="prev page-numbers"
                onClick={() => handlePageChange(filters.page - 1)}
                disabled={filters.page === 1 || !listingData?.total_count}
                style={{ background: 'unset', border: 'none', fontWeight: '600' }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left-short me-1"
                    viewBox="0 0 16 16"
                >
                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                </svg>
                Previous
            </button>

            {renderPageNumbers()}

            <button
                className="next page-numbers"
                onClick={() => handlePageChange(filters.page + 1)}
                disabled={filters.page < 1 || filters.page === totalPages || !listingData?.total_count}
                style={{ background: 'unset', border: 'none', fontWeight: '600' }}
            >
                Next
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-right-short ms-1"
                    viewBox="0 0 16 16"
                >
                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
            </button>

        </nav>
    );
};

export default AllServicesPagination;
