import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { serviceRegister } from "../../services/index.service";
import Loader from "../common/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VALIDATE_REGISTRATION_SCHEMA } from "../../schema/register.schema";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(VALIDATE_REGISTRATION_SCHEMA),
    mode: "onChange",
  });

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const registerService = (userData) => {
    try {
      setLoading(true);
      serviceRegister(userData)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            toast.success(response.data.message, { toastId: "register" });
            navigate(`/otp-verification/?email=${userData.email}`);
          }
        })
        .catch((err) => {
          console.log(err, "error");
          setLoading(false);
        });
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <div className="p-3 p-sm-5">
        <div className="row g-4 g-xl-5 justify-content-between">
          <div className="col-xl-5 d-flex justify-content-center">
            <div className="authentication-wrap overflow-hidden position-relative text-center text-sm-start my-5">
              {/* <!-- Start Header Text --> */}
              <div className="mb-5">
                <h2 className="display-6 fw-semibold mb-3">
                  Welcome back! Please{" "}
                  <span className="font-caveat text-primary">Sign up</span> to
                  continue.
                </h2>
                <p className="mb-0">
                  Unlock a world of exclusive content, enjoy special offers, and
                  be the first to dive into exciting news and updates by joining
                  our community!
                </p>
              </div>
              {/* <!-- /.End Header Text --> */}
              {/* <!-- Start Social Button Wrapper --> */}
              <div className="d-grid gap-3 mb-3">
                {/* <!-- Start Social login Button --> */}
                <a
                  className="align-items-center btn btn-dark btn-lg d-flex linkedin-btn position-relative text-start"
                  href="#"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M8.941 8.041H7.665V15H5.273V8.041H4.271V6.275h1.002V5.188c0-.845.202-1.506.605-1.982C6.281 2.73 6.925 2.5 7.798 2.5c.872 0 1.545.087 2.019.26v1.71H8.91c-.284 0-.508.083-.673.25-.164.166-.246.396-.246.69v1.064h1.885l-.247 1.766z" />
                  </svg>

                  <span className="ms-3">Sign up with Facebook</span>{" "}
                </a>
                {/* <!-- /.End Social login Button --> */}
                {/* <!-- Start Social login Button --> */}
                <a
                  className="align-items-center btn btn-lg btn-light d-flex google-btn position-relative text-start"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-google"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                  </svg>
                  <span className="ms-3">Sign up with Google</span>
                </a>
                {/* <!-- Start Social login Button --> */}
              </div>
              {/* <!-- /.End Social Button Wrapper --> */}
              {/* <!-- Start Text --> */}
              <p className="mb-0">
                We won't post anything without your permission and your personal
                details are kept private
              </p>
              {/* <!-- /.End Text --> */}
              {/* <!-- Start Divider --> */}
              <div className="align-items-center d-flex my-5">
                <hr className="flex-grow-1 m-0" />{" "}
                <span className="fs-16 fw-bold px-3 text-dark">Or</span>
                <hr className="flex-grow-1 m-0" />
              </div>
              {/* <!-- /.End Divider --> */}
              <form
                className="register-form"
                onSubmit={handleSubmit(registerService)}
              >
                {/* <!-- Start Form Group --> */}
                <div className="form-group mb-4">
                  <label className="required">User Name</label>
                  <input
                    type="text"
                    name="username"
                    autoComplete="off"
                    {...register("username")}
                    className={`form-control ${errors?.username?.message ? "is-invalid" : ""
                      }`}
                  />
                  {errors.username && (
                    <div className="invalid-feedback text-start">
                      {errors.username.message}
                    </div>
                  )}
                </div>
                {/* <!-- /.End Form Group --> */}
                {/* <!-- Start Form Group --> */}
                <div className="form-group mb-4">
                  <label className="required">Enter Email</label>
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    className={`form-control ${errors?.email?.message ? "is-invalid" : ""
                      }`}
                    {...register("email")}
                  />
                  {errors?.email && (
                    <div className="invalid-feedback text-start">
                      {errors?.email?.message}
                    </div>
                  )}
                </div>
                {/* <!-- /.End Form Group --> */}
                {/* Start Form Group */}
                <div className="form-group mb-4">
                  <label className="required">Password</label>
                  <input
                    id="password"
                    name="password"
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${errors?.password?.message ? "is-invalid" : ""
                      }`}
                    {...register("password")}
                  // Assuming you have this handler
                  />
                  {errors?.password && (
                    <div className="invalid-feedback text-start" >
                      {errors?.password?.message}
                    </div>
                  )}
                  <i
                    onClick={togglePasswordVisibility}
                    className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"
                      } toggle-password`}
                  ></i>
                </div>
                {/* End Form Group */}

                {/* <!-- Start Form Group --> */}
                <div className="form-group mb-4">
                  <label className="required">Phone</label>
                  <input
                    type="text"
                    name="mobile_number"
                    className={`form-control ${errors?.mobile_number?.message ? "is-invalid" : ""
                      }`}
                    {...register("mobile_number")}
                  />
                  {errors?.mobile_number && (
                    <div className="invalid-feedback text-start">
                      {errors?.mobile_number?.message}
                    </div>
                  )}
                </div>
                {/* <!-- /.End Form Group --> */}

                {/* <!-- Start Form Group --> */}
                <div className="form-group mb-4">
                  <label className="required">User Type</label>
                  <select
                    {...register("user_type")}
                    className={`form-select ${errors.user_type ? "is-invalid" : ""}`}
                  >
                    <option value="">Select User Type</option>
                    <option value="General">General User</option>
                    <option value="Service Provider">Service Provider</option>
                    <option value="Service Expert">Service Expert</option>
                  </select>
                  {errors.user_type && (
                    <div className="invalid-feedback text-start ">{errors.user_type.message}</div>
                  )}
                </div>
                {/* <!-- /.End Form Group --> */}
                {/* <!-- Start Checkbox --> */}
                <div className="form-check mb-4 text-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // checked={credentials.terms}
                    id="flexCheckDefault"
                    name="terms"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    By signing up, you agree to the
                    <Link
                      to="terms-conditions.html"
                      className="text-decoration-underline"
                    >
                      &nbsp;terms of service
                    </Link>
                  </label>
                </div>
                {/* <!-- /.End Checkbox --> */}
                {/* <!-- Start Button --> */}
                <button
                  // onClick={registerService}
                  // disabled={credentials.terms ? false : true}
                  className="btn btn-primary btn-lg w-100"
                  type="submit"
                >
                  Sign Up
                </button>
                {/* <!-- End Button --> */}
              </form>
              {/* <!-- Start Bottom Text --> */}
              <div className="bottom-text mt-4">
                Already have an account?
                <Link to="/login" className="fw-medium text-decoration-underline">
                  Sign In
                </Link>
              </div>
              {/* <!-- /.End Bottom Text --> */}
            </div>
          </div>
          <div className="col-xl-7 d-none d-xl-block">
            <div
              className="background-image bg-light d-flex flex-column h-100 justify-content-center p-5 rounded-4"
              data-image-src="assets/images/lines.svg"
            >
              <div className="py-5 text-center">
                <div className="mb-5">
                  <h2 className="fw-semibold">
                    Effortlessly organize your
                    <br /> workspace with ease.
                  </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable
                    <br /> content of a page when looking at its layout.
                  </p>
                </div>
                <img
                  src="assets/images/png-img/real-time-analytics.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
