import { useState } from "react";
import { serviceForgotPassword } from "../../services/index.service";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import { FORGOT_PASSWORD_SCHEMA } from "../../schema/register.schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const ForgotPassword = () => {

    const [loading, setLoading] = useState(false)

    // useForm hook to manage forms
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FORGOT_PASSWORD_SCHEMA),
        mode: "onChange",
    });

    const forgetPasswordService = (userData) => {
        try {
            setLoading(true)
            serviceForgotPassword(userData).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success(response.data.message, { toastId: "forgot_email_sent" });
                }
            }).catch((err) => {
                console.log(err, "error")
                setLoading(false);
            })
        } catch (error) {
            console.log(error, "error")
            setLoading(false);
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <div className="p-3 p-sm-5">
                <div className="row g-4 g-xl-5 justify-content-between align-items-center">
                    <div className="col-xl-5 d-flex justify-content-center">
                        <div className="authentication-wrap overflow-hidden position-relative text-center text-sm-start my-5">
                            {/* <!-- Start Header Text --> */}
                            <div className="mb-5">
                                <h2 className="display-6 fw-semibold mb-3">Password <span className="font-caveat text-primary">Forgot</span></h2>
                                <p className="mb-0">Fill with your mail to receive instructions on how to reset your password.</p>
                            </div>
                            {/* <!-- /.End Header Text --> */}
                            <form className="register-form" onSubmit={handleSubmit(forgetPasswordService)}>
                                {/* <!-- Start Form Group --> */}
                                <div className="form-group mb-4">
                                    <label className="required">Enter Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        className={`form-control ${errors?.email?.message ? "is-invalid" : ""
                                            }`}
                                        {...register("email")}
                                    />
                                    {errors?.email && (
                                        <div className="invalid-feedback text-start">
                                            {errors?.email?.message}
                                        </div>
                                    )}
                                </div>
                                {/* <!-- Start Button --> */}
                                <button className="btn btn-primary btn-lg w-100" type="submit">Forgot Password</button>
                                {/* <!-- End Button --> */}
                            </form>
                            {/* <!-- Start Bottom Text --> */}
                            <div className="bottom-text text-center mt-4">Remember your password? <Link to="/login" className="fw-medium text-decoration-underline">Log in</Link> </div>
                            {/* <!-- /.End Bottom Text --> */}
                        </div>
                    </div>
                    <div className="col-xl-7 d-none d-xl-block">
                        <div className="background-image bg-light d-flex flex-column h-100 justify-content-center p-5 rounded-4" data-image-src="assets/images/lines.svg">
                            <div className="py-5 text-center">
                                <div className="mb-5">
                                    <h2 className="fw-semibold">Effortlessly organize your<br /> workspace with ease.</h2>
                                    <p>It is a long established fact that a reader will be distracted by the readable<br /> content of a page when looking at its layout. </p>
                                </div>
                                <img src="assets/images/png-img/forgot-password.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;