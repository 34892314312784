import { useState } from "react";
import { serviceResetPassword } from "../../services/index.service";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RESET_PASSWORD_SCHEMA } from "../../schema/register.schema";

const ResetPassword = () => {

    // State to manage visibility of password and confirm password
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();


    // useForm hook to manage forms
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(RESET_PASSWORD_SCHEMA),
        mode: "onChange",
    });
    console.log("errors", errors)
    // Extract query parameters directly
    const queryParams = new URLSearchParams(location.search);
    const uidb64 = queryParams.get("uidb64");
    const token = queryParams.get("token");

    // state htmlFor holding email and password values
    const [credentials, setCredentials] = useState({
        password: '',
        confirm_password: '',
        uidb64: uidb64 ?? '',
        token: token ?? ''
    })

    // Function to toggle visibility of password
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Function to toggle visibility of confirm password
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const resetPasswordService = (userData) => {

        try {
            setLoading(true)
            serviceResetPassword(userData).then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    toast.success(response.data.message, { toastId: "reset_password" });
                    navigate('/login');
                }
            })
                .catch((err) => {
                    console.log(err, "error")
                    toast.error(err.message, { toastId: "reset_password" });
                    setLoading(false);
                })
        } catch (error) {
            toast.error(error.message, { toastId: "reset_password" });
            setLoading(false);
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <div class="p-3 p-sm-5">
                <div class="row g-4 g-xl-5 justify-content-between align-items-center">
                    <div class="col-xl-5 d-flex justify-content-center">
                        <div class="authentication-wrap overflow-hidden position-relative text-center text-sm-start my-5">
                            {/* <!-- Start Header Text --> */}
                            <div class="mb-5">
                                <h2 class="display-6 fw-semibold mb-3">Password <span class="font-caveat text-primary">Reset</span></h2>
                                <p class="mb-0">Fill with your mail to receive instructions on how to reset your password.</p>
                            </div>
                            {/* <!-- /.End Header Text --> */}
                            <form class="register-form" onSubmit={handleSubmit(resetPasswordService)} >

                                {/* Start Password Input */}
                                <div className="form-group mb-4">
                                    <label className="required">Password</label>
                                    <input
                                        name="password"
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        className="form-control password"
                                        autoComplete="off"
                                        {...register("password")}
                                    />

                                    <i
                                        onClick={togglePasswordVisibility}
                                        className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"} toggle-password`}
                                    ></i>
                                    {errors?.password?.message && (
                                        <div className="invalid-feedback text-start d-block" >
                                            {errors?.password?.message}
                                        </div>
                                    )}
                                </div>
                                {/* End Password Input */}

                                {/* Start Confirm Password Input */}
                                <div className="form-group mb-4">
                                    <label className="required">Confirm Password</label>
                                    <input
                                        name="confirm_password"
                                        id="confirmPassword"
                                        type={showConfirmPassword ? "text" : "password"}
                                        className="form-control c-password"
                                        autoComplete="off"
                                        {...register("confirm_password")}
                                    />
                                    <i
                                        onClick={toggleConfirmPasswordVisibility}
                                        className={`fa-regular ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"} toggle-password`}
                                    ></i>
                                    {errors?.confirm_password?.message && (
                                        <div className="invalid-feedback text-start d-block" >
                                            {errors?.confirm_password?.message}
                                        </div>
                                    )}
                                </div>

                                {/* End Confirm Password Input */}

                                {/* <!-- Start Button --> */}
                                <button class="btn btn-primary btn-lg w-100" type="submit">Reset Password</button>
                                {/* <!-- End Button --> */}
                            </form>
                        </div>
                    </div>
                    <div class="col-xl-7 d-none d-xl-block">
                        <div class="background-image bg-light d-flex flex-column h-100 justify-content-center p-5 rounded-4" data-image-src="assets/images/lines.svg">
                            <div class="py-5 text-center">
                                <div class="mb-5">
                                    <h2 class="fw-semibold">Effortlessly organize your<br /> workspace with ease.</h2>
                                    <p>It is a long established fact that a reader will be distracted by the readable<br /> content of a page when looking at its layout. </p>
                                </div>
                                <img src="assets/images/png-img/forgot-password.png" alt="" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;