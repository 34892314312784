import * as yup from "yup";

export const VALIDATE_REGISTRATION_SCHEMA = yup.object({
    username: yup
        .string()
        .required("Username is required")
        .min(3, "Minimum 3 number of characters are allowed")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^$|^[a-zA-Z0-9]*$/,
            "Username must contain only alphanumeric characters"
        ),
    email: yup
        .string()
        .required("Email is required")
        .trim("The email cannot include leading and trailing spaces")
        .email("Invalid email")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email"
        ),
    password: yup
        .string()
        .strict(true)
        .required("Password is required")
        .min(8, "Minimum 8 number of characters are allowed")
        .max(30, "Maximum 30 number of characters are allowed")
        .matches(
            /^(?=\s*\S*\s*[a-z])/,
            "Password must contain at least one lowercase letter"
        )
        .matches(
            /^(?=\s*\S*\s*[A-Z])/,
            "Password must contain at least one uppercase letter"
        )
        .matches(/^(?=\s*\S*\s*[0-9])/, "Password must contain at least one number")
        .matches(
            /(?=\s*\S*\s*[!@#\$%\^&\*])/,
            "Password must contain at least one special character"
        )
        .matches(/^[^\s]+$/, "Password should not contain whitespace"),
    mobile_number: yup
        .string()
        .required("Phone number is required")
        .min(10, "Phone number must be at least 10 digits")
        .max(15, "Phone number cannot exceed 14 digits"),
    user_type: yup.string().required("User type is required"),
});

export const LOGIN_SCHEMA = yup.object({
    username: yup
        .string()
        .required("Username is required")
        .min(3, "Minimum 3 number of characters are allowed")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^$|^[a-zA-Z0-9]*$/,
            "Username must contain only alphanumeric characters"
        ),

    password: yup
        .string()
        .strict(true)
        .required("Password is required")
        .min(8, "Minimum 8 number of characters are allowed")
        .max(30, "Maximum 30 number of characters are allowed")
        .matches(
            /^(?=\s*\S*\s*[a-z])/,
            "Password must contain at least one lowercase letter"
        )
        .matches(
            /^(?=\s*\S*\s*[A-Z])/,
            "Password must contain at least one uppercase letter"
        )
        .matches(/^(?=\s*\S*\s*[0-9])/, "Password must contain at least one number")
        .matches(
            /(?=\s*\S*\s*[!@#\$%\^&\*])/,
            "Password must contain at least one special character"
        )
        .matches(/^[^\s]+$/, "Password should not contain whitespace"),

});

export const FORGOT_PASSWORD_SCHEMA = yup.object({
    email: yup
        .string()
        .required("Email is required")
        .trim("The email cannot include leading and trailing spaces")
        .email("Invalid email")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email"
        ),
});

export const RESET_PASSWORD_SCHEMA = yup.object({
    password: yup
        .string()
        .strict(true)
        .required('Password is required')
        .min(8, 'Minimum 8 number of characters are allowed')
        .max(30, 'Maximum 30 number of characters are allowed')
        .matches(/^(?=\s*\S*\s*[a-z])/, 'Password contains at least one lowercase letter')
        .matches(/^(?=\s*\S*\s*[A-Z])/, 'Password contains at least one uppercase letter')
        .matches(/^(?=\s*\S*\s*[0-9])/, 'Password contains at least one number')
        .matches(/(?=\s*\S*\s*[!@#\$%\^&\*])/, 'Password contains at least one special character')
        .matches(/^[^\s]+$/, 'Password should not contain whitespace'),
    confirm_password: yup
        .string()
        .required('Confirm password is required')
        .min(8, 'Minimum 8 number of characters are allowed')
        .max(30, 'Maximum 30 number of characters are allowed')
        .oneOf([yup.ref('password')], 'Passwords must match')
        .matches(/^[^\s]+$/, 'Confirm Password should not contain whitespace')
});

export const VALIDATE_QUOTE_SCHEMA = yup.object({
    full_name: yup
        .string()
        .required("Full name is required")
        .min(3, "Minimum 3 number of characters are allowed")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/,
            "Username must contain only alphanumeric characters"
        ),
    email: yup
        .string()
        .required("Email is required")
        .trim("The email cannot include leading and trailing spaces")
        .email("Invalid email")
        .max(50, "Maximum 50 number of characters are allowed")
        .matches(
            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email"
        ),
    mobile_number: yup
        .string()
        .required("Mobile number is required")
        .min(10, "Mobile number must be at least 10 digits")
        .max(15, "Mobile number cannot exceed 14 digits"),
    comment: yup.string()
        .notRequired("")
        .max(500, "Comment cannot exceed 500 characters"),
});
