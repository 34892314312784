import React from "react";
import TeamUserCard from "./Cards/teamUserCard";

const About = () => {
  const userCardData = [
    {
      img: "assets/images/avatar/01.jpg",
      name: "Ethan Blackwood",
      designation: "Co-founder",
    },
    {
      img: "assets/images/avatar/02.jpg",
      name: "Edwin Martins",
      designation: "Product Manager",
    },
    {
      img: "assets/images/avatar/03.jpg",
      name: "Alexander Kaminski",
      designation: "cc",
    },
    {
      img: "assets/images/avatar/04.jpg",
      name: "Pranoti Deshpande",
      designation: "DevOps",
    },
    {
      img: "assets/images/avatar/05.jpg",
      name: "Alexander Steele",
      designation: "Data analysis",
    },
    {
      img: "assets/images/avatar/06.jpg",
      name: "Marcus Knight",
      designation: "Security Engineer",
    },

    {
      img: "assets/images/avatar/07.jpg",
      name: "Alexander Kaminski",
      designation: "Support Engineer",
    },
    {
      img: "assets/images/avatar/02.jpg",
      name: "Gabriel North",
      designation: "Security Engineer",
    },
  ];

  return (
    <>
      <>
        {/* <!-- start page header --> */}
        <section
          className="hero mx-3 overflow-hidden position-relative py-4 py-lg-5 rounded-4 bg-light hero-about bg-bottom js-bg-image bg-cover"
          data-image-src="assets/images/pattern.svg"
        >
          {/* <!-- <img className="bg-image" src="assets/images/header/01.jpg" alt="Image"> --> */}
          <div className="container overlay-content py-5">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-md-10 col-lg-10">
                {/* <!-- start section header --> */}
                <div
                  className="section-header text-center"
                  data-aos="fade-down"
                >
                  {/* <!-- start description --> */}
                  <div className="bg-primary d-inline-block fs-14 mb-3 px-4 py-2 rounded-5 sub-title text-white">
                    7+ YEARS EXPERIENCED IN FIELD
                  </div>
                  {/* <!-- end /. section header sub title --> */}
                  {/* <!-- start section header --> */}
                  <h2 className="display-4 fw-semibold mb-3 section-header__title text-capitalize mb-0">
                    ListOn was founded in 2023 by Alexander with a{" "}
                    <span className="font-caveat text-primary">
                      vision to your original
                    </span>{" "}
                    vision or inspiration.
                  </h2>
                  {/* <!-- end /. section header title --> */}
                </div>
                {/* <!-- end /. section header --> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end /. page header --> */}
        {/* <!-- start about video section --> */}
        <div className="container">
          {/* <!-- start about video --> */}
          <div className="about-video position-relative mx-3 rounded-4 overflow-hidden about-video-top">
            {/* <!-- Image Parallax --> */}
            <img
              src="assets/images/about/bg-02.jpg"
              alt=""
              className="about-img js-image-parallax"
            />
            <a
              className="align-items-center bg-blur d-flex justify-content-center popup-youtube position-absolute rounded-circle start-50 text-white top-50 translate-middle video-icon z-1"
              href="https://www.youtube.com/watch?v=0O2aH4XLbto"
            >
              <i className="fa-solid fa-play fs-20"></i>
            </a>
          </div>
          {/* <!-- end /. about video --> */}
        </div>
        {/* <!-- start story section --> */}
        <div className="py-5 border-bottom">
          <div className="container py-4">
            <div className="row">
              <div className="col-md-6 pe-xxl-5">
                <div className="text-end fw-medium">OUR STORY:</div>
              </div>
              <div className="col-md-6 ps-xxl-5">
                <h3 className="display-6 mb-4">About ListOn Brand</h3>
                <p className="lead">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it look like readable English.
                </p>
                <a
                  href="#"
                  className="text-primary fw-semibold d-inline-block mt-4"
                >
                  Let’s Check open position
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-top pt-2">
              <div className="mb-5 fw-medium">OUR ACHIEVEMENTS</div>
              <div className="row g-4 justify-content-center">
                <div className="col-sm-6 col-lg-3">
                  <div className="counter-box align-items-center bg-light d-flex display-5 fw-semibold justify-content-center m-auto rounded-circle text-primary">
                    <span className="counter">3,00 </span>+
                  </div>
                  <h5 className="fs-18 mb-0 mt-3 text-center">Job posted</h5>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter-box align-items-center bg-light d-flex display-5 fw-semibold justify-content-center m-auto rounded-circle text-primary">
                    <span className="counter">2,50 </span>+
                  </div>
                  <h5 className="fs-18 mb-0 mt-3 text-center">
                    Successful hires
                  </h5>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter-box align-items-center bg-light d-flex display-5 fw-semibold justify-content-center m-auto rounded-circle text-primary">
                    <span className="counter">10</span>M +
                  </div>
                  <h5 className="fs-18 mb-0 mt-3 text-center">
                    Monthly visits
                  </h5>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter-box align-items-center bg-light d-flex display-5 fw-semibold justify-content-center m-auto rounded-circle text-primary">
                    <span className="counter">593 </span>+
                  </div>
                  <h5 className="fs-18 mb-0 mt-3 text-center">
                    Verified companies
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end /. story section --> */}
        {/* <!-- start about section --> */}
        <div className="py-5">
          <div className="container py-4">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-md-10 col-lg-8">
                {/* <!-- start section header --> */}
                <div
                  className="section-header text-center mb-5"
                  data-aos="fade-down"
                >
                  {/* <!-- start subtitle --> */}
                  <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                    About us
                  </div>
                  {/* <!-- end /. subtitle --> */}
                  {/* <!-- start title --> */}
                  <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                    Explore over 2.5 million people travel around the world.
                  </h2>
                  {/* <!-- end /. title --> */}
                  {/* <!-- start description --> */}
                  <div className="sub-title fs-16">
                    Discover exciting categories.{" "}
                    <span className="text-primary fw-semibold">
                      Find what you’re looking for.
                    </span>
                  </div>
                  {/* <!-- end /. description --> */}
                </div>
                {/* <!-- end /. section header --> */}
              </div>
            </div>
            <div className="row g-4 justify-content-between">
              <div className="col-lg-6 col-xl-5">
                <div className="column-text-box left">
                  <h6 className="fs-14 fw-semibold heading-sm l-spacing-1 position-relative text-primary text-uppercase title-line">
                    ABOUT ListOn GROUP
                  </h6>
                  <h3 className="display-6">
                    Innovative Buildings with new materials
                  </h3>
                  <p>
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text, and a search
                    for 'lorem ipsum' will uncover many web sites still in their
                    infancy. Various versions have evolved over the years,
                    sometimes by accident, sometimes on purpose (injected humour
                    and the like).
                  </p>
                  <blockquote className="about-blockquote display-6 font-caveat fst-italic my-3">
                    {" "}
                    It uses a dictionary of over 200 Latin words, combined with
                    a handful of model sentence.
                  </blockquote>
                  <div className="mb-3">
                    <div className="align-items-center d-flex fs-18 fw-semibold justify-content-between mb-2">
                      <span>Prosperity</span>
                      <span>60%</span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Default striped example"
                      aria-valuenow="60"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar progress-bar-striped"
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="align-items-center d-flex fs-18 fw-semibold justify-content-between mb-2">
                      <span>Quality</span>
                      <span>85%</span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Default striped example"
                      aria-valuenow="85"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar progress-bar-striped"
                        style={{ width: "85%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="">
                    <div className="align-items-center d-flex fs-18 fw-semibold justify-content-between mb-2">
                      <span>Guaranty</span>
                      <span>40%</span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Default striped example"
                      aria-valuenow="40"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        className="progress-bar progress-bar-striped"
                        style={{ width: "40%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ps-xxl-5">
                {/* <!-- start about image masonry --> */}
                <div className="ps-xl-4 position-relative">
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="about-image-wrap mb-3 rounded-4">
                        <img
                          src="assets/images/about/01.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover about-image-one rounded-3"
                        />
                      </div>
                      <div className="about-image-wrap rounded-4">
                        <img
                          src="assets/images/about/02.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover about-image-two rounded-3"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="about-image-wrap mb-3 rounded-4">
                        <img
                          src="assets/images/about/03.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover about-image-three rounded-3"
                        />
                      </div>
                      <div className="about-image-wrap rounded-4">
                        <img
                          src="assets/images/about/04.jpg"
                          alt=""
                          className="h-100 w-100 object-fit-cover about-image-four rounded-3"
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    src="assets/images/png-img/about-shape-1.png"
                    alt="About Shape"
                    className="banner-shape-one position-absolute"
                  />
                  <img
                    src="assets/images/png-img/about-shape-2.png"
                    alt="About Shape"
                    className="banner-shape-two position-absolute"
                  />
                </div>
                {/* <!-- end /. about image masonry --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end /. about section --> */}
        {/* <!-- start awards section --> */}
        <div className="bg-dark mb-3 mx-3 py-5 rounded-4">
          <div className="container py-4">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-md-10 col-lg-8">
                {/* <!-- start section header --> */}
                <div
                  className="section-header text-center mb-5"
                  data-aos="fade-down"
                >
                  {/* <!-- start subtitle --> */}
                  <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                    Awards
                  </div>
                  {/* <!-- end /. subtitle --> */}
                  {/* <!-- start section header --> */}
                  <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize text-white">
                    Our Awards
                  </h2>
                  {/* <!-- end /. title --> */}
                  {/* <!-- start description --> */}
                  <div className="sub-title fs-16 text-white">
                    Find a job you love.{" "}
                    <span className="text-primary fw-semibold">
                      Set your career interests.
                    </span>
                  </div>
                  {/* <!-- end /. description --> */}
                </div>
                {/* <!-- end /. section header --> */}
              </div>
            </div>
            <div className="row justify-content-center g-4 g-xxl-5">
              <div className="col-6 col-md-3">
                <img
                  src="assets/images/png-img/award-01.png"
                  alt=""
                  className="img-fluid px-xl-4"
                />
                <div className="mt-3 pt-3 text-center">
                  <h5 className="text-primary">World Architecture Festival</h5>
                  <div className="text-white">Jun 23, 2023</div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src="assets/images/png-img/award-02.png"
                  alt=""
                  className="img-fluid px-xl-4"
                />
                <div className="mt-3 pt-3 text-center">
                  <h5 className="text-primary">
                    Venice Biennale of Architecture
                  </h5>
                  <div className="text-white">Jun 23, 2023</div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src="assets/images/png-img/award-03.png"
                  alt=""
                  className="img-fluid px-xl-4"
                />
                <div className="mt-3 pt-3 text-center">
                  <h5 className="text-primary">
                    Tamayouz Excellence Award for Architecture
                  </h5>
                  <div className="text-white">Jun 23, 2023</div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <img
                  src="assets/images/png-img/award-04.png"
                  alt=""
                  className="img-fluid px-xl-4"
                />
                <div className="mt-3 pt-3 text-center">
                  <h5 className="text-primary">RIBA President's Medals</h5>
                  <div className="text-white">Jun 23, 2023</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end /. awards section --> */}
        {/* <!-- start team section --> */}
        <div className="py-5">
          <div className="container py-4">
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-6">
                {/* <!-- start section header --> */}
                <div className="section-header mb-5" data-aos="fade-down">
                  {/* <!-- start subtitle --> */}
                  <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                    Our Team
                  </div>
                  {/* <!-- end /. subtitle --> */}
                  {/* <!-- start title --> */}
                  <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                    Meet Our Team
                  </h2>
                  {/* <!-- end /. title --> */}
                  {/* <!-- start description --> */}
                  <div className="sub-title fs-16">
                    A team of experienced and dedicated professionals who are
                    passionate about helping our clients find their dream
                    properties. From our agents to our support staff, everyone
                    on our team is committed to providing personalized service,
                    expert guidance, and exceptional results.
                  </div>
                  {/* <!-- end /. description --> */}
                </div>
                {/* <!-- end /. section header --> */}
              </div>
            </div>
            <div className="row justify-content-center g-4 gx-xxl-5">
              {userCardData?.map((ele, index) => {
                return (
                  <TeamUserCard
                    key={index}
                    img={ele.img}
                    name={ele.name}
                    designation={ele.designation}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* <!-- end /. team section --> */}
        {/* <!-- start customers section --> */}
        <div className="py-5 bg-light">
          <div className="container py-4">
            <div className="row justify-content-center">
              <div className="col-sm-10 col-md-10 col-lg-8">
                {/* <!-- start section header --> */}
                <div
                  className="section-header text-center mb-5"
                  data-aos="fade-down"
                >
                  {/* <!-- start subtitle --> */}
                  <div className="d-inline-block font-caveat fs-1 fw-medium section-header__subtitle text-capitalize text-primary">
                    Our Customers
                  </div>
                  {/* <!-- end /. subtitle --> */}
                  {/* <!-- start title --> */}
                  <h2 className="display-5 fw-semibold mb-3 section-header__title text-capitalize">
                    Trusted By Thousands News Of Companies
                  </h2>
                  {/* <!-- end /. title --> */}
                  {/* <!-- start description --> */}
                  <div className="sub-title fs-16">
                    Discover exciting categories.{" "}
                    <span className="text-primary fw-semibold">
                      Find what you’re looking for.
                    </span>
                  </div>
                  {/* <!-- end /. description --> */}
                </div>
                {/* <!-- end /. section header --> */}
              </div>
            </div>
            <div className="row align-items-center justify-content-center g-2 g-sm-3 g-md-4">
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/01.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/02.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/03.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/04.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/05.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
              <div className="col-6 col-sm-4 col-lg-3 col-xxl-2">
                <div className="border-0 card card-hover d-block header-cat-box px-3 py-3 rounded-4 text-center">
                  <img
                    src="assets/images/brand-logo/06.png"
                    alt=""
                    height="60"
                    className="company-logo img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end /. customers section --> */}

        {/* <!-- Optional JavaScript --> */}
      </>
    </>
  );
};

export default About;
